export default class AppAssetsConfig {
  static FALLBACK_LOADER_GIF = '/assets/gifs/synapp-logo.gif'
  static CHOOSE_YOUR_IDENTITY = '/assets/images/choose-your-identity-bg.png'
  static MOCK_PROFILE_IMAGE_01 = '/assets/images/image_01.png'
  static MOCK_PROFILE_IMAGE = '/assets/images/image.png'
  static SYNAPP_LOGO = '/assets/images/synapp-logo.png'
  static BEAUJON_HOSPITAL_LOGO = '/assets/images/beaujon-hospital.png'
  static SIGNATURE_DEMO_LOGO = '/assets/images/signature-demo.png'
  static MOCK_DCM_MRI = '/assets/dcm/bbmri.dcm'
  static BROADCAST_MESSAGE = '/assets/images/device-message.png'
}
