import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'

export const patientSettingsSidebarUtils = (t) => {
  let menuGroup = [
    {
      id: 'profile-information',
      title: t('AppModuleNames.ProfileInformation'),
      url: AppRoutingConfig.PROFILE_INFORMATION
    },
    {
      id: 'payment-information',
      title: t('AppModuleNames.PaymentInformation'),
      url: AppRoutingConfig.PAYMENT_INFORMATION
    }
  ]

  return menuGroup
}
