import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { translate } from 'i18n/i18n'

// get request management
export const get_request_management = createAsyncThunk(
  'requestManagement/get_request_management',
  async ({ showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/profile/request/config/get`)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get community request management
export const get_community_request_management = createAsyncThunk(
  'requestManagement/get_community_request_management',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/get-request-config`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update request management
export const update_request_management = createAsyncThunk(
  'requestManagement/update_request_management',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/request/config`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.message.length > 0
              ? response?.data?.[0]?.message
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update community request management
export const update_community_request_management = createAsyncThunk(
  'requestManagement/update_community_request_management',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/update-request-config`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.message.length > 0
              ? response?.data?.[0]?.message
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get quetionnaire
export const get_questionnaire = createAsyncThunk(
  'requestManagement/get_questionnaire',
  async ({ showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/profile/questionnaire/get`)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update quetionnaire
export const update_questionnaire = createAsyncThunk(
  'requestManagement/update_questionnaire',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/questionnaire/create`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get tele expertise instructions
export const get_tele_expertise_instructions = createAsyncThunk(
  'requestManagement/get_tele_expertise_instructions',
  async ({ showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/profile/instructions/te/get`)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update tele expertise intructions
export const update_tele_expertise_instructions = createAsyncThunk(
  'requestManagement/update_tele_expertise_instructions',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/instructions/te/create`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get second opinion instructions
export const get_second_opinion_instructions = createAsyncThunk(
  'requestManagement/get_second_opinion_instructions',
  async ({ showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/profile/instructions/so/get`)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update second opinion intructions
export const update_second_opinion_instructions = createAsyncThunk(
  'requestManagement/update_second_opinion_instructions',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/instructions/so/create`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
