import { Avatar, Space } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'

const TourTitle = ({ title }) => {
  const { t } = useTranslation()
  return (
    <div className="tour-title">
      <Space align="center" className="title-from">
        <Avatar size={36} icon={<UserOutlined />} />
        <>
          <strong>Imad</strong>
          {t('productTour.from')}
        </>
      </Space>
      <br />
      <h3>{title}</h3>
    </div>
  )
}

export default TourTitle
