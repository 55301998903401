import { createSlice } from '@reduxjs/toolkit'
import {
  add_members,
  get_all_members_list,
  get_non_members_list,
  my_community_filter_list,
  my_community_te_list,
  get_new_members_list,
  my_community_so_list
} from '../services/myCommunityService'

const initialState = {
  myCommunityFilterList: {
    data: null,
    loading: false
  },
  myCommunityTeList: {
    data: null,
    loading: false
  },
  myCommunitySoList: {
    data: null,
    loading: false
  },
  selectedMembers: [],
  getAllMembersList: {
    loading: false,
    allMembersData: [],
    pagination: {}
  },
  getNewMembersList: {
    loading: false,
    newMembersData: [],
    pagination: {}
  },
  getNonMembersList: {
    loading: false,
    nonMembersData: []
  },
  addMembers: {
    loading: false
  }
}

export const myCommunitySlice = createSlice({
  name: 'myCommunity',
  initialState,
  reducers: {
    addMember: (state, action) => {
      state.selectedMembers = [...state.selectedMembers, { ...action.payload }]
    },
    removeMember: (state, action) => {
      state.selectedMembers = state.selectedMembers.filter((item) => item.id !== action.payload)
    },
    clearAddedMember: (state) => {
      state.selectedMembers = []
    },
    favouriteMembarCard: (state, action) => {
      let memberCard = state.getAllMembersList.allMembersData?.find(
        (cardData) => cardData.id === action.payload
      )
      memberCard.isFavourite = !memberCard.isFavourite
    },
    favouriteNewMembarCard: (state, action) => {
      let memberCard = state.getNewMembersList.newMembersData?.find(
        (cardData) => cardData.id === action.payload
      )
      memberCard.isFavourite = !memberCard.isFavourite
    },
    manageMemberRequestCard: (state, action) => {
      state.getNewMembersList.newMembersData = state.getNewMembersList.newMembersData.filter(
        (item) => item.id !== action.payload
      )
      state.getNewMembersList.pagination = {
        ...state.getNewMembersList.pagination,
        totalCount: state.getNewMembersList.pagination.totalCount - 1
      }
    },
    clearNonMembersData: (state) => {
      state.getNonMembersList.nonMembersData = []
    }
  },
  extraReducers: (builder) => {
    // Get Community filter List
    builder.addCase(my_community_filter_list.pending, (state) => {
      state.myCommunityFilterList.loading = true
    })
    builder.addCase(my_community_filter_list.fulfilled, (state, action) => {
      const allFilterVal = {
        key: '0',
        label: 'All',
        value: '0'
      }
      const response = action.payload?.response?.map((element) => ({
        key: element?.id,
        label: element?.community_title,
        value: element?.id
      }))
      state.myCommunityFilterList.data = [allFilterVal, ...response]
      state.myCommunityFilterList.loading = false
    })
    builder.addCase(my_community_filter_list.rejected, (state) => {
      state.myCommunityFilterList.loading = false
    })

    // Get Community te List
    builder.addCase(my_community_te_list.pending, (state) => {
      state.myCommunityTeList.loading = true
    })
    builder.addCase(my_community_te_list.fulfilled, (state, action) => {
      state.myCommunityTeList.data = action.payload?.response
      state.myCommunityTeList.loading = false
    })
    builder.addCase(my_community_te_list.rejected, (state) => {
      state.myCommunityTeList.loading = false
    })

    // Get Community so List
    builder.addCase(my_community_so_list.pending, (state) => {
      state.myCommunitySoList.loading = true
    })
    builder.addCase(my_community_so_list.fulfilled, (state, action) => {
      state.myCommunitySoList.data = action.payload?.response
      state.myCommunitySoList.loading = false
    })
    builder.addCase(my_community_so_list.rejected, (state) => {
      state.myCommunitySoList.loading = false
    })

    // Get All Members List
    builder.addCase(get_all_members_list.pending, (state) => {
      state.getAllMembersList.loading = true
    })
    builder.addCase(get_all_members_list.fulfilled, (state, action) => {
      const updatedAllMembersData = action.payload?.response?.data?.map((item) => {
        return {
          id: item?.id,
          userNo: item?.user_no,
          isLoading: false,
          isVerified: item?.is_profile_verified,
          profileImage: item?.user_url,
          profileName: `${item?.title} ${item?.first_name} ${item?.last_name}`,
          isFavourite: item?.has_favorite ? true : false,
          onHoliday: false,
          wasActive:
            item?.account_setting?.status === 'available' && item?.account_setting?.replies_within
              ? true
              : false,
          repliesWithIn: item?.account_setting?.replies_within,
          isExpert:
            item?.physician_hcp_info?.physician_level &&
            item?.physician_hcp_info?.physician_level !== 'None'
              ? true
              : false,
          physicianLevelTag: item?.physician_hcp_info?.physician_level,
          department: item?.physician_hcp_info?.profession_type?.display_name,
          speciality: item?.physician_hcp_info?.specialty_type?.display_name,
          speciality_slug: item?.physician_hcp_info?.specialty_slug,
          address:
            item?.address?.city && item?.address?.country
              ? `${item?.address?.city}, ${item?.address?.country}`
              : undefined,
          institute: item?.place_of_works?.[0]?.name,
          description: item?.physician_hcp_info?.bio,
          freeAssitance: item?.request_config?.is_free_asst_req ? true : false,
          teleExpertiseFree: item?.physician_hcp_billing_info?.is_te_free ? false : true,
          caseRate: item?.physician_hcp_billing_info?.fee_for_te,
          acceptsSSN: false,
          ratings: 4.9,
          totalRatings: 64,
          languages: item?.physician_hcp_info?.languages,
          teleExpertiseFee: item?.physician_hcp_billing_info?.is_te_free
            ? 'Free'
            : item?.physician_hcp_billing_info?.fee_for_te,
          secondOpinionWithVideo: item?.physician_hcp_billing_info?.is_so_wv_free
            ? 'Free'
            : item?.physician_hcp_billing_info?.fee_for_so_wv,
          secondOpinionWithOutVideo: item?.physician_hcp_billing_info?.is_so_wov_free
            ? 'Free'
            : item?.physician_hcp_billing_info?.fee_for_so_wov,
          identityNo: item?.user_no
        }
      })
      state.getAllMembersList.allMembersData = updatedAllMembersData
      state.getAllMembersList.pagination = action.payload?.response?.pagination
      state.getAllMembersList.loading = false
    })
    builder.addCase(get_all_members_list.rejected, (state) => {
      state.getAllMembersList.loading = false
    })
    // Get Non Members List
    builder.addCase(get_non_members_list.pending, (state) => {
      state.getNonMembersList.loading = true
    })
    builder.addCase(get_non_members_list.fulfilled, (state, action) => {
      const updatedMembersData = action.payload?.response?.data?.map((item) => {
        return {
          id: item?.id,
          name: `${item?.title} ${item?.first_name} ${item?.last_name}`,
          speciality: item?.physician_hcp_info?.specialty_type?.d,
          city: item?.address?.city,
          country: item?.address?.country,
          createdOn: item?.createdAt,
          label: `${item?.title} ${item?.first_name} ${item?.last_name} ${item?.physician_hcp_info?.specialty_type?.d ? `| ${item?.physician_hcp_info?.specialty_type?.d}` : ''} ${item?.address?.city ? `| ${item?.address?.city}` : ''} ${item?.address?.country ? `| ${item?.address?.country}` : ''}`,
          value: `${item?.first_name}_${item?.last_name}_${item?.id}`,
          physician_url: item?.physician_url
        }
      })
      state.getNonMembersList.nonMembersData = updatedMembersData
      state.getNonMembersList.loading = false
    })
    builder.addCase(get_non_members_list.rejected, (state) => {
      state.getNonMembersList.loading = true
    })
    // Add Members
    builder.addCase(add_members.pending, (state) => {
      state.addMembers.loading = true
    })
    builder.addCase(add_members.fulfilled, (state) => {
      state.addMembers.loading = false
    })
    builder.addCase(add_members.rejected, (state) => {
      state.addMembers.loading = true
    })
    // Get New Members List
    builder.addCase(get_new_members_list.pending, (state) => {
      state.getNewMembersList.loading = true
    })
    builder.addCase(get_new_members_list.fulfilled, (state, action) => {
      const updatedNewMembersData = action.payload?.response?.data?.map((item) => {
        return {
          id: item?.id,
          userNo: item?.user_no,
          isLoading: false,
          isVerified: item?.is_profile_verified,
          profileImage: item?.user_url,
          profileName: `${item?.title} ${item?.first_name} ${item?.last_name}`,
          isFavourite: item?.has_favorite ? true : false,
          onHoliday: false,
          wasActive:
            item?.account_setting?.status === 'available' && item?.account_setting?.replies_within
              ? true
              : false,
          repliesWithIn: item?.account_setting?.replies_within,
          isExpert:
            item?.physician_hcp_info?.physician_level &&
            item?.physician_hcp_info?.physician_level !== 'None'
              ? true
              : false,
          physicianLevelTag: item?.physician_hcp_info?.physician_level,
          department: item?.physician_hcp_info?.profession_type?.display_name,
          speciality: item?.physician_hcp_info?.specialty_type?.display_name,
          speciality_slug: item?.physician_hcp_info?.specialty_slug,
          address:
            item?.address?.city && item?.address?.country
              ? `${item?.address?.city}, ${item?.address?.country}`
              : undefined,
          institute: item?.place_of_works?.[0]?.name,
          description: item?.physician_hcp_info?.bio,
          freeAssitance: item?.request_config?.is_free_asst_req ? true : false,
          teleExpertiseFree: item?.physician_hcp_billing_info?.is_te_free ? false : true,
          caseRate: item?.physician_hcp_billing_info?.fee_for_te,
          acceptsSSN: false,
          ratings: 4.9,
          totalRatings: 64,
          languages: item?.physician_hcp_info?.languages,
          teleExpertiseFee: item?.physician_hcp_billing_info?.is_te_free
            ? 'Free'
            : item?.physician_hcp_billing_info?.fee_for_te,
          secondOpinionWithVideo: item?.physician_hcp_billing_info?.is_so_wv_free
            ? 'Free'
            : item?.physician_hcp_billing_info?.fee_for_so_wv,
          secondOpinionWithOutVideo: item?.physician_hcp_billing_info?.is_so_wov_free
            ? 'Free'
            : item?.physician_hcp_billing_info?.fee_for_so_wov,
          identityNo: item?.user_no
        }
      })
      state.getNewMembersList.newMembersData = updatedNewMembersData
      state.getNewMembersList.pagination = action.payload?.response?.pagination
      state.getNewMembersList.loading = false
    })
    builder.addCase(get_new_members_list.rejected, (state) => {
      state.getNewMembersList.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  addMember,
  removeMember,
  clearAddedMember,
  favouriteMembarCard,
  favouriteNewMembarCard,
  clearNonMembersData,
  manageMemberRequestCard
} = myCommunitySlice.actions

export default myCommunitySlice.reducer
