import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import { roles } from '../../constants'
import UserDetailsProvider from 'context/UserDetailsProvider'
import RequireAuth from 'app/requireAuth'
import MainLayout from 'components/layouts/main-layout'
import RoleBasedComponent from './RoleBasedComponent'
import i18n from 'i18n/i18n'
import * as lazy from './lazyComponents'
import PrimaryLayout from 'components/layouts/primary-layout'
import ErrorBoundary from 'components/common/error-boundary'
import { Navigate } from 'react-router-dom'
import CaseLayout from 'components/layouts/case-layout'

const editor_author_roles = {
  [i18n.t('AppModuleNames.Home')]: <lazy.AuthorEditorHome />,
  [i18n.t('AppModuleNames.Dashboard')]: <lazy.AuthorEditorDashboard />,
  [i18n.t('AppModuleNames.MyRequests')]: <lazy.AllRequests />,
  [i18n.t('AppModuleNames.MyCommunity')]: <lazy.AuthorEditorMyCommunity />,
  [i18n.t('AppModuleNames.ProfileInformation')]: <lazy.ProfileInformation />
}

const physician_hcp_roles = {
  [i18n.t('AppModuleNames.Home')]: <lazy.Home />,
  [i18n.t('AppModuleNames.Dashboard')]: <lazy.Dashboard />,
  [i18n.t('AppModuleNames.MyRequests')]: <lazy.MyRequests />,
  [i18n.t('AppModuleNames.MyCommunity')]: <lazy.MyCommunity />,
  [i18n.t('AppModuleNames.MyLibrary')]: <lazy.MyLibrary />,
  [i18n.t('AppModuleNames.ProfileInformation')]: <lazy.ProfileInformation />,
  [i18n.t('AppModuleNames.MyPatients')]: <lazy.MyPatients />
}

const renderComponentBasedOnRole = {
  [roles.superAdmin]: {
    [i18n.t('AppModuleNames.Dashboard')]: <lazy.SynappAdminDashboard />,
    [i18n.t('AppModuleNames.MyRequests')]: <lazy.SynappAdminMyRequests />
  },
  [roles.physician]: { ...physician_hcp_roles },
  [roles.hcp]: { ...physician_hcp_roles },
  [roles.editor]: { ...editor_author_roles },
  [roles.author]: { ...editor_author_roles },
  [roles.patient]: {
    [i18n.t('AppModuleNames.Home')]: <lazy.PatientHome />,
    [i18n.t('AppModuleNames.MyRequests')]: <lazy.PatientMyRequests />,
    [i18n.t('AppModuleNames.MyLibrary')]: <lazy.PatientMyLibrary />,
    [i18n.t('AppModuleNames.ProfileInformation')]: <lazy.PatientProfileInformation />
  }
}

const getComponentBasedOnModuleName = (module) => {
  return Object.keys(renderComponentBasedOnRole).reduce((acc, role) => {
    acc[role] = renderComponentBasedOnRole[role][module]
    return acc
  }, {})
}

const CommonWrapper = ({ Layout, allowedRoles }) => {
  return (
    <UserDetailsProvider>
      <RequireAuth allowedRoles={allowedRoles}>
        <Layout />
      </RequireAuth>
    </UserDetailsProvider>
  )
}

export const multi_role_routes = [
  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: (
      <CommonWrapper
        Layout={MainLayout}
        allowedRoles={[
          roles.physician,
          roles.hcp,
          roles.superAdmin,
          roles.patient,
          roles.editor,
          roles.author
        ]}
      />
    ),
    children: [
      {
        path: AppRoutingConfig.HOME,
        element: (
          <RoleBasedComponent
            componentMap={getComponentBasedOnModuleName(i18n.t('AppModuleNames.Home'))}
          />
        )
      },
      {
        path: AppRoutingConfig.DASHBOARD,
        element: (
          <RoleBasedComponent
            componentMap={getComponentBasedOnModuleName(i18n.t('AppModuleNames.Dashboard'))}
          />
        )
      },
      {
        path: AppRoutingConfig.MY_REQUESTS,
        element: (
          <RoleBasedComponent
            componentMap={getComponentBasedOnModuleName(i18n.t('AppModuleNames.MyRequests'))}
          />
        )
      },
      {
        path: AppRoutingConfig.MY_LIBRARY,
        element: (
          <RoleBasedComponent
            componentMap={getComponentBasedOnModuleName(i18n.t('AppModuleNames.MyLibrary'))}
          />
        )
      },
      { path: AppRoutingConfig.DIRECTORY, element: <lazy.Directory /> }
    ]
  },
  {
    path: AppRoutingConfig.SETTINGS,
    element: (
      <CommonWrapper
        Layout={PrimaryLayout}
        allowedRoles={[roles.physician, roles.hcp, roles.patient, roles.author, roles.editor]}
      />
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Navigate to={AppRoutingConfig.PROFILE_INFORMATION} replace />
      },
      {
        path: AppRoutingConfig.PROFILE_INFORMATION,
        element: (
          <RoleBasedComponent
            componentMap={getComponentBasedOnModuleName(
              i18n.t('AppModuleNames.ProfileInformation')
            )}
          />
        )
      }
    ]
  },
  {
    path: AppRoutingConfig.SETTINGS,
    element: (
      <CommonWrapper
        Layout={PrimaryLayout}
        allowedRoles={[roles.physician, roles.hcp, roles.author, roles.editor]}
      />
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: AppRoutingConfig.PROFESSIONAL_INFORMATION,
        element: <lazy.ProfessionalInformation />
      }
    ]
  },
  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: (
      <CommonWrapper
        Layout={MainLayout}
        allowedRoles={[roles.physician, roles.hcp, roles.author, roles.editor]}
      />
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: AppRoutingConfig.MY_PATIENTS,
        element: <lazy.MyPatients />
      },
      {
        path: AppRoutingConfig.MY_COMMUNITY,
        element: (
          <RoleBasedComponent
            componentMap={getComponentBasedOnModuleName(i18n.t('AppModuleNames.MyCommunity'))}
          />
        )
      }
    ]
  },
  {
    path: AppRoutingConfig.CONFIGURE,
    element: (
      <CommonWrapper
        Layout={PrimaryLayout}
        allowedRoles={[roles.superAdmin, roles.author, roles.editor]}
      />
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Navigate to={AppRoutingConfig.COMMUNITY_INFO} replace />
      },
      {
        path: AppRoutingConfig.COMMUNITY_INFO,
        element: <lazy.CommunityInfo />
      },
      {
        path: AppRoutingConfig.COMMUNITY_REQUEST_MANAGEMENT,
        element: <lazy.CommunityRequestManagement />
      },
      {
        path: AppRoutingConfig.INTERFACE_MANAGEMENT,
        element: <lazy.InterfaceManagement />
      },
      {
        path: AppRoutingConfig.COMMUNITY_NOTIFICATIONS,
        element: <lazy.CommunityNotifications />
      },
      {
        path: AppRoutingConfig.COMMUNITY_BILLING_INFO,
        element: <lazy.CommunityBillingInfo />
      }
    ]
  },
  {
    element: (
      <CommonWrapper
        Layout={CaseLayout}
        allowedRoles={[roles.physician, roles.hcp, roles.superAdmin, roles.editor, roles.author]}
      />
    ),
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: AppRoutingConfig.CREATE_MEDICAL_REPORT,
        element: <lazy.AddEditMedicalReport />
      },
      {
        path: AppRoutingConfig.INVOICE_TEMPLATE,
        element: <lazy.InvoiceTemplate />
      }
    ]
  }
]
