import { createSlice } from '@reduxjs/toolkit'
import {
  create_community_medical_report,
  create_physician_medical_report,
  create_synappadmin_medical_report,
  generate_so_medical_report,
  generate_te_medical_report,
  get_community_medical_report,
  get_community_medical_reports,
  get_physician_medical_report,
  get_physician_medical_reports,
  get_synappadmin_medical_report,
  get_synappadmin_medical_reports,
  preview_medical_report,
  update_community_medical_report,
  update_physician_medical_report,
  update_synappadmin_medical_report
} from '../services/medicalReportService'

const initialState = {
  getSynappAdminMedicalReports: {
    data: null,
    loading: false
  },
  getSynappAdminMedicalReport: {
    data: null,
    loading: false
  },
  createUpdateSynappAdminMedicalReport: {
    loading: false
  },
  getPhysicianMedicalReports: {
    data: null,
    loading: false
  },
  getPhysicianMedicalReport: {
    data: null,
    loading: false
  },
  createUpdatePhysicianMedicalReport: {
    loading: false
  },
  getCommunityMedicalReports: {
    data: null,
    loading: false
  },
  getCommunityMedicalReport: {
    data: null,
    loading: false
  },
  createUpdateCommunityMedicalReport: {
    loading: false
  },
  previewMedicalReport: {
    data: null,
    loading: false
  },
  generateTeMedicalReport: {
    data: null,
    loading: false
  },
  generateSoMedicalReport: {
    data: null,
    loading: false
  }
}

export const medicalReportSlice = createSlice({
  name: 'medicalReport',
  initialState,
  reducers: {
    clearMedicalReportData: (state) => {
      state.getSynappAdminMedicalReport = initialState.getSynappAdminMedicalReport
      state.getPhysicianMedicalReport = initialState.getPhysicianMedicalReport
    }
  },
  extraReducers: (builder) => {
    // Get synappadmin Medical Reports
    builder.addCase(get_synappadmin_medical_reports.pending, (state) => {
      state.getSynappAdminMedicalReports.loading = true
    })
    builder.addCase(get_synappadmin_medical_reports.fulfilled, (state, action) => {
      state.getSynappAdminMedicalReports.loading = false
      state.getSynappAdminMedicalReports.data = action?.payload?.response
    })
    builder.addCase(get_synappadmin_medical_reports.rejected, (state) => {
      state.getSynappAdminMedicalReports.loading = false
    })

    // Get synappadmin Medical Report
    builder.addCase(get_synappadmin_medical_report.pending, (state) => {
      state.getSynappAdminMedicalReport.loading = true
    })
    builder.addCase(get_synappadmin_medical_report.fulfilled, (state, action) => {
      state.getSynappAdminMedicalReport.loading = false
      state.getSynappAdminMedicalReport.data = action?.payload?.response
    })
    builder.addCase(get_synappadmin_medical_report.rejected, (state) => {
      state.getSynappAdminMedicalReport.loading = false
    })

    // Create synappadmin Medical Report
    builder.addCase(create_synappadmin_medical_report.pending, (state) => {
      state.createUpdateSynappAdminMedicalReport.loading = true
    })
    builder.addCase(create_synappadmin_medical_report.fulfilled, (state) => {
      state.createUpdateSynappAdminMedicalReport.loading = false
    })
    builder.addCase(create_synappadmin_medical_report.rejected, (state) => {
      state.createUpdateSynappAdminMedicalReport.loading = false
    })

    // Update synappadmin Medical Report
    builder.addCase(update_synappadmin_medical_report.pending, (state) => {
      state.createUpdateSynappAdminMedicalReport.loading = true
    })
    builder.addCase(update_synappadmin_medical_report.fulfilled, (state) => {
      state.createUpdateSynappAdminMedicalReport.loading = false
    })
    builder.addCase(update_synappadmin_medical_report.rejected, (state) => {
      state.createUpdateSynappAdminMedicalReport.loading = false
    })

    // Get physician Medical Reports
    builder.addCase(get_physician_medical_reports.pending, (state) => {
      state.getPhysicianMedicalReports.loading = true
    })
    builder.addCase(get_physician_medical_reports.fulfilled, (state, action) => {
      state.getPhysicianMedicalReports.loading = false
      state.getPhysicianMedicalReports.data = action?.payload?.response
    })
    builder.addCase(get_physician_medical_reports.rejected, (state) => {
      state.getPhysicianMedicalReports.loading = false
    })

    // Get physician Medical Report
    builder.addCase(get_physician_medical_report.pending, (state) => {
      state.getPhysicianMedicalReport.loading = true
    })
    builder.addCase(get_physician_medical_report.fulfilled, (state, action) => {
      state.getPhysicianMedicalReport.loading = false
      state.getPhysicianMedicalReport.data = action?.payload?.response
    })
    builder.addCase(get_physician_medical_report.rejected, (state) => {
      state.getPhysicianMedicalReport.loading = false
    })

    // Create physician Medical Report
    builder.addCase(create_physician_medical_report.pending, (state) => {
      state.createUpdatePhysicianMedicalReport.loading = true
    })
    builder.addCase(create_physician_medical_report.fulfilled, (state) => {
      state.createUpdatePhysicianMedicalReport.loading = false
    })
    builder.addCase(create_physician_medical_report.rejected, (state) => {
      state.createUpdatePhysicianMedicalReport.loading = false
    })

    // Update physician Medical Report
    builder.addCase(update_physician_medical_report.pending, (state) => {
      state.createUpdatePhysicianMedicalReport.loading = true
    })
    builder.addCase(update_physician_medical_report.fulfilled, (state) => {
      state.createUpdatePhysicianMedicalReport.loading = false
    })
    builder.addCase(update_physician_medical_report.rejected, (state) => {
      state.createUpdatePhysicianMedicalReport.loading = false
    })

    // Get community Medical Reports
    builder.addCase(get_community_medical_reports.pending, (state) => {
      state.getCommunityMedicalReports.loading = true
    })
    builder.addCase(get_community_medical_reports.fulfilled, (state, action) => {
      state.getCommunityMedicalReports.loading = false
      state.getCommunityMedicalReports.data = action?.payload?.response
    })
    builder.addCase(get_community_medical_reports.rejected, (state) => {
      state.getCommunityMedicalReports.loading = false
    })

    // Get community Medical Report
    builder.addCase(get_community_medical_report.pending, (state) => {
      state.getCommunityMedicalReport.loading = true
    })
    builder.addCase(get_community_medical_report.fulfilled, (state, action) => {
      state.getCommunityMedicalReport.loading = false
      state.getCommunityMedicalReport.data = action?.payload?.response
    })
    builder.addCase(get_community_medical_report.rejected, (state) => {
      state.getCommunityMedicalReport.loading = false
    })

    // Create community Medical Report
    builder.addCase(create_community_medical_report.pending, (state) => {
      state.createUpdateCommunityMedicalReport.loading = true
    })
    builder.addCase(create_community_medical_report.fulfilled, (state) => {
      state.createUpdateCommunityMedicalReport.loading = false
    })
    builder.addCase(create_community_medical_report.rejected, (state) => {
      state.createUpdateCommunityMedicalReport.loading = false
    })

    // Update community Medical Report
    builder.addCase(update_community_medical_report.pending, (state) => {
      state.createUpdateCommunityMedicalReport.loading = true
    })
    builder.addCase(update_community_medical_report.fulfilled, (state) => {
      state.createUpdateCommunityMedicalReport.loading = false
    })
    builder.addCase(update_community_medical_report.rejected, (state) => {
      state.createUpdateCommunityMedicalReport.loading = false
    })

    // Preview Medical Reports
    builder.addCase(preview_medical_report.pending, (state) => {
      state.previewMedicalReport.loading = true
    })
    builder.addCase(preview_medical_report.fulfilled, (state, action) => {
      state.previewMedicalReport.loading = false
      state.previewMedicalReport.data = action?.payload?.response
    })
    builder.addCase(preview_medical_report.rejected, (state) => {
      state.previewMedicalReport.loading = false
    })

    // Generate TE Medical Reports
    builder.addCase(generate_te_medical_report.pending, (state) => {
      state.generateTeMedicalReport.loading = true
    })
    builder.addCase(generate_te_medical_report.fulfilled, (state, action) => {
      state.generateTeMedicalReport.loading = false
      state.generateTeMedicalReport.data = action?.payload?.response
    })
    builder.addCase(generate_te_medical_report.rejected, (state) => {
      state.generateTeMedicalReport.loading = false
    })

    // Generate SO Medical Reports
    builder.addCase(generate_so_medical_report.pending, (state) => {
      state.generateSoMedicalReport.loading = true
    })
    builder.addCase(generate_so_medical_report.fulfilled, (state, action) => {
      state.generateSoMedicalReport.loading = false
      state.generateSoMedicalReport.data = action?.payload?.response
    })
    builder.addCase(generate_so_medical_report.rejected, (state) => {
      state.generateSoMedicalReport.loading = false
    })
  }
})

export const { clearMedicalReportData } = medicalReportSlice.actions

export default medicalReportSlice.reducer
