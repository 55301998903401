import { createSlice } from '@reduxjs/toolkit'
import { add_broadcast_message } from '../services/broadCastService'

const initialState = {
  addBroadCast: {
    loading: false
  }
}

export const broadCastSlice = createSlice({
  name: 'broadCast',
  initialState,
  extraReducers: (builder) => {
    // Add Broadcast Message
    builder.addCase(add_broadcast_message.pending, (state) => {
      state.addBroadCast.loading = true
    })
    builder.addCase(add_broadcast_message.fulfilled, (state) => {
      state.addBroadCast.loading = false
    })
    builder.addCase(add_broadcast_message.rejected, (state) => {
      state.addBroadCast.loading = false
    })
  }
})

export default broadCastSlice.reducer
