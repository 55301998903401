import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get policy list
export const get_policy_list = createAsyncThunk(
  'configurePolicies/get_policy_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/policy/get`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update policy
export const update_policy = createAsyncThunk(
  'configurePolicies/update_policy',
  async (
    { payload, showNotification, setShowEditorForEdit, handleGetData },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/admin/policy/update`, payload)
      if (response.status === 0) {
        setShowEditorForEdit(false)
        handleGetData()
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update faq
export const update_faq = createAsyncThunk(
  'configurePolicies/update_faq',
  async (
    { payload, showNotification, handleCloseDialog, handleGetFaqData },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/admin/faq/update`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetFaqData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get faq list
export const get_faq_list = createAsyncThunk(
  'configurePolicies/get_faq_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/faq/get?page=${payload.page}&pageSize=${payload.pageSize}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
