import { useNavigate } from 'react-router-dom'
import { Alert, Button, Flex, Space, Typography } from 'antd'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import { useTranslation } from 'react-i18next'
import './ErrorBoundary.scss'

export default function ErrorBoundary() {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* navigation dependencies */
  const navigate = useNavigate()
  /* navigation dependencies */

  return (
    <div className="error-boundary-container">
      <Flex align="center" justify="center">
        <Space direction="vertical">
          <Alert
            message={
              <Flex vertical align="center" justify="center" gap="0.5rem">
                <Typography.Title level={2}>{t('CommonUtils.SomethingWentWrong')}</Typography.Title>
                <Typography.Title level={3}>
                  {t('CommonUtils.SorryForTheInconvenience')}
                </Typography.Title>
                <Typography.Title level={3}>
                  {t('CommonUtils.OurTeamHasBeenNotified')}
                </Typography.Title>
                <Button size="large" onClick={() => navigate(AppRoutingConfig.HOME)}>
                  {t('AppModuleNames.Home')}
                </Button>
              </Flex>
            }
            type="error"
          />
        </Space>
      </Flex>
    </div>
  )
}
