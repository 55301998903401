import { Outlet } from 'react-router-dom'
import './AuthSecondaryLayout.scss'

const AuthSecondaryLayout = () => {
  return (
    <div className="auth-secondary-layout-container">
      <Outlet />
    </div>
  )
}

export default AuthSecondaryLayout
