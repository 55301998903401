import { Avatar, Col, Flex, Modal, Row, Typography } from 'antd'
import { openTourModel } from '../../../redux/reducer/appReducer'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { TakeATourIcon } from 'assets/svg'
import AppAssetsConfig from 'assets/config/AppAssetsConfig'
import { AppService } from '../../../redux/services'
import './Navbar.scss'

const SynappTourDialog = ({ open, onClose }) => {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* dispatch dependencies */
  const dispatch = useDispatch()
  /* dispatch dependencies */

  /* dialog close dependencies */
  const handleCloseDialog = () => {
    onClose()
    dispatch(AppService.mark_tour_completed({ payload: {} }))
  }
  /* dialog close dependencies */

  const handleTourModel = () => {
    dispatch(openTourModel())
    onClose()
  }

  return (
    <Modal
      centered
      footer={false}
      open={open}
      destroyOnClose
      onCancel={handleCloseDialog}
      width={600}
      className="synapp-tour-dialog"
      maskClosable={false}
    >
      <Flex gap="1.5rem" style={{ marginTop: 10 }}>
        <Row gutter={[24, 24]}>
          <Col xs={24} sm={24} md={3} lg={3}>
            <Avatar size="large" src={AppAssetsConfig.MOCK_PROFILE_IMAGE_01} />
          </Col>
          <Col xs={24} sm={24} md={21} lg={21}>
            <Typography.Title level={4} style={{ color: 'var(--bright-white)' }}>
              {t('CommonUtils.LearnHowToUseSynappExpertiseInTwoMinutes')}
            </Typography.Title>
            <Typography.Title
              level={2}
              style={{
                color: 'var(--bright-white)',
                borderBottom: '2px solid var(--bright-white)',
                width: 'fit-content',
                cursor: 'pointer',
                marginTop: 10
              }}
              onClick={handleTourModel}
            >
              <Flex gap="0.3rem" align="center">
                <TakeATourIcon />
                {t('CommonUtils.TakeATour')}
              </Flex>
            </Typography.Title>
          </Col>
        </Row>
      </Flex>
    </Modal>
  )
}

export default SynappTourDialog
