import { createSlice } from '@reduxjs/toolkit'
import {
  get_configure_notifications,
  get_settings_notifications,
  update_configure_notifications,
  update_settings_notifications
} from '../services/notificationsService'
import { decryptData, encryptData } from 'assets/config/AppEncryptDecryptConfig'

export const notificationSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: {
      loading: false,
      notifications_info: {}
    },
    updateNotifications: {
      loading: false
    },
    configureNotifications: {
      loading: false,
      data: null
    },
    updateConfigureNotifications: {
      loading: false
    }
  },
  extraReducers: (builder) => {
    // Get Settings Notifications
    builder.addCase(get_settings_notifications.pending, (state) => {
      state.notifications.loading = true
    })
    builder.addCase(get_settings_notifications.fulfilled, (state, action) => {
      state.notifications.loading = false
      state.notifications.notifications_info = action.payload?.response
    })
    builder.addCase(get_settings_notifications.rejected, (state) => {
      state.notifications.loading = false
    })

    // Update Settings Notifications
    builder.addCase(update_settings_notifications.pending, (state) => {
      state.updateNotifications.loading = true
    })
    builder.addCase(update_settings_notifications.fulfilled, (state) => {
      state.updateNotifications.loading = false

      const user_data = decryptData('user')
      const modified_user_data = { ...user_data }
      if (modified_user_data) modified_user_data.is_notification_config_updated = true
      encryptData('user', modified_user_data)
    })
    builder.addCase(update_settings_notifications.rejected, (state) => {
      state.updateNotifications.loading = false
    })

    // Get Configure Notifications
    builder.addCase(get_configure_notifications.pending, (state) => {
      state.configureNotifications.loading = true
    })
    builder.addCase(get_configure_notifications.fulfilled, (state, action) => {
      state.configureNotifications.loading = false
      state.configureNotifications.data = action.payload?.response
    })
    builder.addCase(get_configure_notifications.rejected, (state) => {
      state.configureNotifications.loading = false
    })

    // Update Configure Notifications
    builder.addCase(update_configure_notifications.pending, (state) => {
      state.updateConfigureNotifications.loading = true
    })
    builder.addCase(update_configure_notifications.fulfilled, (state) => {
      state.updateConfigureNotifications.loading = false
    })
    builder.addCase(update_configure_notifications.rejected, (state) => {
      state.updateConfigureNotifications.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
// export const {} = notificationSlice.actions

export default notificationSlice.reducer
