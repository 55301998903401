// Second Opinion Service
import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import axiosInstance from 'axiosInstance'

import { displayMultipleNotifications } from 'utils/notificationsUtils'

// Get Request Info
export const get_video_call_token = createAsyncThunk(
  'vc/get_video_call_token',
  async ({ payload, showNotification, navigate }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/so/get-call-token`, payload)

      if (response.status === 0) {
        navigate(AppRoutingConfig.VIDEO_CALL)
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
