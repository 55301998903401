import { createSlice } from '@reduxjs/toolkit'
import { get_my_patients_list, get_my_so_patients_list } from '../services/myPatientsService'

export const myPatientsSlice = createSlice({
  name: 'myPatients',
  initialState: {
    getMyPatientsList: {
      loading: false,
      getMyPatients: [],
      pagination: {}
    },
    getMySoPatientsList: {
      loading: false,
      data: null
    }
  },
  extraReducers: (builder) => {
    // Get My Patients List
    builder.addCase(get_my_patients_list.pending, (state) => {
      state.getMyPatientsList.loading = true
    })
    builder.addCase(get_my_patients_list.fulfilled, (state, action) => {
      state.getMyPatientsList.getMyPatients = action.payload?.response?.data
      state.getMyPatientsList.pagination = action.payload?.response?.pagination
      state.getMyPatientsList.loading = false
    })
    builder.addCase(get_my_patients_list.rejected, (state) => {
      state.getMyPatientsList.loading = false
    })

    // Get My SO Patients List
    builder.addCase(get_my_so_patients_list.pending, (state) => {
      state.getMySoPatientsList.loading = true
    })
    builder.addCase(get_my_so_patients_list.fulfilled, (state, action) => {
      state.getMySoPatientsList.data = action.payload?.response
      state.getMySoPatientsList.loading = false
    })
    builder.addCase(get_my_so_patients_list.rejected, (state) => {
      state.getMySoPatientsList.loading = false
    })
  }
})

export default myPatientsSlice.reducer
