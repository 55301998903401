import { Button, Collapse, Empty, Flex, Spin, Typography } from 'antd'
import { useTranslation } from 'react-i18next'
import { PlusOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import CreateFaqDialog from './CreateFaqDialog'
import { QuestionMarkIcon } from 'assets/svg'
import { useDispatch, useSelector } from 'react-redux'
import { useNotify } from 'context/NotificationProvider'
import { ConfigurePoliciesService } from '../../../../../../../redux/services'
import Pagination from 'components/common/pagination'
import './Faq.scss'

const Faq = ({ activeTab, isSideBarWidget = false }) => {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* redux dependencies */
  const dispatch = useDispatch()
  const { getFaqList } = useSelector((state) => state.configurePolicies)
  /* redux dependencies */

  /* notification dependencies */
  const { showNotification } = useNotify()
  /* notification dependencies */

  /* state dependencies */
  const [editFaqData, setEditFaqData] = useState({})
  /* state dependencies */

  /* table dependencies */
  const [pageNo, setPageNo] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  /* table dependencies */

  /* These functions handles get faq list API call */
  const handleGetFaqData = () => {
    const getFaqListPayload = {
      page: pageNo,
      pageSize
    }
    dispatch(
      ConfigurePoliciesService.get_faq_list({
        payload: getFaqListPayload,
        showNotification
      })
    )
  }
  /* These functions handles get faq list API call */

  /* dialog dependencies */
  const [createFaqDialogOpen, setCreateFaqDialogOpen] = useState(false)

  const handleOpenCreateFaqDialog = (event, rowData) => {
    event.stopPropagation()
    setCreateFaqDialogOpen(true)
    if (rowData) {
      setEditFaqData({ isFaqEdit: true, ...rowData })
    }
  }

  const handleCloseCreateCreateFaqDialog = () => {
    setCreateFaqDialogOpen(false)
    setEditFaqData({})
  }

  const createFaqProps = useMemo(
    () => ({
      open: createFaqDialogOpen,
      onClose: handleCloseCreateCreateFaqDialog,
      handleGetFaqData,
      editFaqData
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createFaqDialogOpen]
  )
  /* dialog dependencies */

  /*useEffect hook to fetch faq data */
  useEffect(() => {
    if (activeTab === 5 || isSideBarWidget) handleGetFaqData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNo, pageSize, activeTab])
  /*useEffect hook to fetch faq data */

  const collapseLabel = (collapseData) => {
    return (
      <Flex justify="space-between">
        <Flex align="center" gap={'1rem'}>
          <div className="collapse-label">
            <QuestionMarkIcon />
          </div>
          <Typography.Title level={2}>{collapseData?.section_name}</Typography.Title>
        </Flex>
        {!isSideBarWidget && (
          <Typography.Link
            style={{ userSelect: 'none', fontSize: 14 }}
            onClick={(event) => handleOpenCreateFaqDialog(event, collapseData)}
          >
            {t('CommonUtils.Edit')}
          </Typography.Link>
        )}
      </Flex>
    )
  }

  const collapseContent = (collapsebleData) => {
    return (
      <Flex vertical>
        {collapsebleData?.map((item, index) => {
          return (
            <Flex key={index} gap={'1rem'} style={{ marginBottom: '10px' }}>
              <div className="collapse-content">
                <QuestionMarkIcon color="var(--cerulean)" />
              </div>
              <Flex vertical>
                <Typography.Title level={2}>{item.question}</Typography.Title>
                <Typography.Title
                  level={4}
                  style={{
                    margin: 0,
                    color: 'var(--steel-blue)',
                    fontWeight: 400,
                    fontSize: '14px'
                  }}
                >
                  {item.answer}
                </Typography.Title>
              </Flex>
            </Flex>
          )
        })}
      </Flex>
    )
  }

  const panelStyle = {
    marginBottom: 24,
    borderRadius: 8,
    border: '1px solid va(--alice-blue)',
    boxShadow: 'var(--default-box-shadow)'
  }

  const collapseItemData = useMemo(() => {
    return getFaqList?.getFaq?.map((item, index) => {
      return {
        key: index,
        label: collapseLabel(item),
        children: collapseContent(item?.question_answer),
        style: panelStyle
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFaqList])

  return (
    <div>
      <Flex vertical gap="1rem">
        {!isSideBarWidget && (
          <div>
            <Button
              size="large"
              icon={<PlusOutlined />}
              onClick={(event) => handleOpenCreateFaqDialog(event)}
            >
              {t('ConfigurePolicies.AddFAQ')}
            </Button>
          </div>
        )}
        <Spin spinning={getFaqList?.loading}>
          {getFaqList?.getFaq?.length > 0 ? (
            <div className="faq-collapse">
              <Collapse
                bordered={true}
                size="large"
                expandIconPosition="end"
                destroyInactivePanel
                accordion
                items={collapseItemData}
              />
              <Pagination
                pageNo={pageNo}
                total={getFaqList?.pagination?.totalCount}
                pageSize={pageSize}
                setPageSize={setPageSize}
                onPageChange={(page) => setPageNo(page)}
              />
            </div>
          ) : (
            <Empty style={{ margin: '3rem auto' }} />
          )}
        </Spin>
      </Flex>
      <CreateFaqDialog {...createFaqProps} />
    </div>
  )
}

export default Faq
