import { translate } from 'i18n/i18n'

export const displayMultipleNotifications = (notificationData, showNotification) => {
  if (notificationData?.length > 0) {
    notificationData.forEach((item) => {
      showNotification({
        type: 'error',
        message:
          item.message.length > 0 ? item.message : translate('CommonUtils.SomethingWentWrong!')
      })
    })
  }
}
