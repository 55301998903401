import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'

export const configureSidebarUtils = (t) => {
  let menuGroup = [
    {
      id: 'community-info',
      title: t('AppModuleNames.CommunityInfo'),
      url: AppRoutingConfig.COMMUNITY_INFO
    },
    {
      id: 'community-request-management',
      title: t('AppModuleNames.RequestManagement'),
      url: AppRoutingConfig.COMMUNITY_REQUEST_MANAGEMENT
    },
    {
      id: 'community-notifications',
      title: t('AppModuleNames.Notifications'),
      url: AppRoutingConfig.COMMUNITY_NOTIFICATIONS
    },
    {
      id: 'community-billing-info',
      title: t('AppModuleNames.BillingInfo'),
      url: AppRoutingConfig.COMMUNITY_BILLING_INFO
    }
  ]

  return menuGroup
}
