import { Avatar, Badge, Flex, List, Typography } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useDispatch } from 'react-redux'
import { readNotification } from '../../../redux/reducer/notificationsWidgetReducer'
import { NotificationsWidgetService } from '../../../redux/services'
import { useNotify } from 'context/NotificationProvider'
import './NotificationWidget.scss'

const NotificationList = ({ listData, className = '', loading }) => {
  /* redux dependencies */
  const dispatch = useDispatch()
  /* redux dependencies */

  /* notification dependencies */
  const { showNotification } = useNotify()
  /* notification dependencies */

  /* This function handle read notification related funtionality */
  const handleNotificationRead = (notificationDetail) => {
    dispatch(readNotification(notificationDetail?.id))
    const updateNotificationObject = {
      mark_as_read: [notificationDetail?.id]
    }
    dispatch(
      NotificationsWidgetService.update_notification({
        payload: updateNotificationObject,
        showNotification
      })
    )
  }
  /* This function handle read notification related funtionality */

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={listData}
        className={className}
        loading={loading}
        renderItem={(item) => {
          const todayDate = dayjs()
          const notificationDate = dayjs(item?.createdAt)
          const hoursDifference = todayDate.diff(notificationDate, 'hour')
          const minutesDifference = todayDate.diff(notificationDate, 'minute') % 60

          return (
            <List.Item
              className={`notification-list ${item?.is_read && 'read-notification-list'}`}
              onMouseEnter={() => handleNotificationRead(item)}
            >
              <List.Item.Meta
                avatar={<Avatar size={40} icon={<UserOutlined />} />}
                title={<Typography.Title level={3}>{item?.user_name}</Typography.Title>}
                description={
                  <>
                    <Flex vertical gap={'0.5rem'}>
                      <Typography.Title level={4} type="secondary" style={{ fontWeight: 400 }}>
                        {item?.message}
                      </Typography.Title>
                      {/* {item.notificationDescription?.length && (
                      <Typography.Title
                        level={4}
                        type="secondary"
                        style={{ fontWeight: 400, margin: 0 }}
                        className="notification-description"
                      >
                        {item.notificationDescription}
                      </Typography.Title>
                    )} */}
                      <Flex align="center" justify="space-between">
                        <Typography.Title
                          level={4}
                          type="secondary"
                          style={{ fontWeight: 400, margin: 0 }}
                        >
                          {`${hoursDifference > 0 ? `${hoursDifference} hours` : ''} ${minutesDifference} minutes`}
                        </Typography.Title>
                        {!item?.is_read && <Badge dot />}
                      </Flex>
                    </Flex>
                  </>
                }
              />
            </List.Item>
          )
        }}
      />
    </>
  )
}

export default NotificationList
