import { createSlice } from '@reduxjs/toolkit'
import {
  begin_reset_password,
  confirm_reset_password,
  end_signup,
  login,
  send_otp_for_community_email,
  send_otp_for_community_phone,
  send_otp_for_email,
  send_otp_for_phone,
  signup,
  signup_with_rpps,
  verify_login,
  verify_otp_for_community_email,
  verify_otp_for_community_phone
} from '../services/authService'
import { encryptData } from 'assets/config/AppEncryptDecryptConfig'
import { AppPermissionsConfig } from 'assets/config/AppPermissionsConfig'

const initialState = {
  login: {
    loading: false,
    identifier: '',
    method: '',
    login_flow_id: '',
    flow_id: '',
    password: ''
  },
  signup: {
    loading: false,
    registration_flow_id: '',
    user_details: {
      identity: '',
      firstname: '',
      lastname: '',
      password: '',
      type: '',
      profession: '',
      speciality: '',
      rpps_number: null,
      country_code: '',
      city: '',
      place_of_work: '',
      country: '',
      email: '',
      address: '',
      phone: '',
      gender: '',
      dob: '',
      zipcode: ''
    }
  },
  sendOtpForPhone: {
    flow_id: ''
  },
  sendOtpForEmail: {
    flow_id: ''
  },
  sendOtpForCommunityEmail: {
    flow_id: '',
    loading: false
  },
  verifyOtpForCommunityEmail: {
    loading: false
  },
  sendOtpForCommunityPhone: {
    flow_id: '',
    loading: false
  },
  verifyOtpForCommunityPhone: {
    loading: false
  },
  endSignup: {
    loading: false
  },
  resetPassword: {
    loading: false,
    flow_id: '',
    identifier: ''
  },
  confirmResetPassword: {
    loading: false
  },
  endLogin: {}
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    selectIdentity: (state, action) => {
      state.signup.user_details.identity = action.payload
    },
    seedUserDetails: (state, action) => {
      state.signup.user_details.firstname = action.payload?.firstname
      state.signup.user_details.lastname = action.payload?.lastname
      state.signup.user_details.email = action.payload?.email
      state.signup.user_details.phone = action.payload?.phone
      state.signup.user_details.gender = action.payload?.gender
      state.signup.user_details.dob = action.payload?.dob
      state.signup.user_details.country = action.payload?.country
      state.signup.user_details.zipcode = action.payload?.zipcode
      state.signup.user_details.address = action.payload?.address
      state.signup.user_details.title = action.payload?.title
      state.signup.user_details.type = state.signup.user_details?.identity
      state.signup.user_details.profession = action.payload?.profession
      state.signup.user_details.speciality = action.payload?.speciality
      state.signup.user_details.city = action.payload?.city
      state.signup.user_details.place_of_work = action.payload?.place_of_work
    },
    clearSignupUserDetails: (state) => {
      state.signup.user_details = initialState.signup.user_details
    },
    removeBroadCastMessage: (state) => {
      state.endLogin.broad_cast_msg = {}
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.login.loading = true
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.login.method = action.payload?.requestPayload.method
      state.login.identifier = action.payload?.requestPayload.identifier
      state.login.password = action.payload?.requestPayload.password
      state.login.login_flow_id = action.payload?.response.login_flow_id
      state.login.flow_id = action.payload?.response.flow_id
      state.login.loading = false
    })

    // End sign up
    builder.addCase(end_signup.pending, (state) => {
      state.endSignup.loading = true
    })
    builder.addCase(end_signup.fulfilled, (state) => {
      state.endSignup.loading = false
    })
    builder.addCase(end_signup.rejected, (state) => {
      state.endSignup.loading = false
    })

    // Sign up step 1 Validate Sign up

    builder.addCase(signup.pending, (state) => {
      state.signup.loading = true
    })
    builder.addCase(signup.rejected, (state) => {
      state.signup.loading = false
    })

    builder.addCase(signup.fulfilled, (state, action) => {
      state.signup.user_details.phone = action.payload?.requestPayload?.phone
      state.signup.user_details.country_code = action.payload?.requestPayload?.country_code
      state.signup.user_details.email = action.payload?.requestPayload?.email
      state.signup.registration_flow_id = action.payload?.response.registration_flow_id
      state.signup.loading = false
    })

    // Send OTP for phone
    builder.addCase(send_otp_for_phone.fulfilled, (state, action) => {
      state.sendOtpForPhone.flow_id = action.payload?.response?.flow_id
    })

    // Send OTP for email
    builder.addCase(send_otp_for_email.fulfilled, (state, action) => {
      state.sendOtpForEmail.flow_id = action.payload?.response?.flow_id
    })

    // Send OTP for community email
    builder.addCase(send_otp_for_community_email.pending, (state) => {
      state.sendOtpForCommunityEmail.loading = true
    })
    builder.addCase(send_otp_for_community_email.fulfilled, (state, action) => {
      state.sendOtpForCommunityEmail.flow_id = action.payload?.response?.flow_id
      state.sendOtpForCommunityEmail.loading = false
    })
    builder.addCase(send_otp_for_community_email.rejected, (state) => {
      state.sendOtpForCommunityEmail.loading = false
    })

    // Verify OTP for community email
    builder.addCase(verify_otp_for_community_email.pending, (state) => {
      state.verifyOtpForCommunityEmail.loading = true
    })
    builder.addCase(verify_otp_for_community_email.fulfilled, (state) => {
      state.verifyOtpForCommunityEmail.loading = false
    })
    builder.addCase(verify_otp_for_community_email.rejected, (state) => {
      state.verifyOtpForCommunityEmail.loading = false
    })

    // Send OTP for community phone
    builder.addCase(send_otp_for_community_phone.pending, (state) => {
      state.sendOtpForCommunityPhone.loading = true
    })
    builder.addCase(send_otp_for_community_phone.fulfilled, (state, action) => {
      state.sendOtpForCommunityPhone.flow_id = action.payload?.response?.flow_id
      state.sendOtpForCommunityPhone.loading = false
    })
    builder.addCase(send_otp_for_community_phone.rejected, (state) => {
      state.sendOtpForCommunityPhone.loading = false
    })

    // Verify OTP for community phone
    builder.addCase(verify_otp_for_community_phone.pending, (state) => {
      state.verifyOtpForCommunityPhone.loading = true
    })
    builder.addCase(verify_otp_for_community_phone.fulfilled, (state) => {
      state.verifyOtpForCommunityPhone.loading = false
    })
    builder.addCase(verify_otp_for_community_phone.rejected, (state) => {
      state.verifyOtpForCommunityPhone.loading = false
    })

    // Sign up step 2 with RPPS
    builder.addCase(signup_with_rpps.fulfilled, (state, action) => {
      state.signup.user_details.rpps_number = action.payload?.requestPayload?.filters?.rpps
      state.signup.user_details.firstname = action.payload?.response?.results?.[0]?.firstname
      state.signup.user_details.lastname = action.payload?.response?.results?.[0]?.lastname
      state.signup.user_details.place_of_work =
        action.payload?.response?.results?.[0]?.institution_names
      state.signup.user_details.city = action.payload?.response?.results?.[0]?.cities
      state.signup.user_details.profession = action.payload?.response?.results?.[0]?.professions
      state.signup.user_details.speciality = action.payload?.response?.results?.[0]?.specialities
    })

    // verify_login
    builder.addCase(verify_login.fulfilled, (state, action) => {
      const response = action.payload?.response

      const permissions = AppPermissionsConfig.getAdminPermissionsObjFromArr(
        response,
        response?.auth_user_role?.sub_role || response?.auth_user_role?.role || ''
      )

      const userData = {
        ...response,
        isAuthenticated: true,
        label: 'Individual',
        value: response?.id,
        permissions
      }

      state.endLogin = userData
      encryptData('user', userData)
      encryptData('selectedProfile', userData)
    })

    // begin reset password
    builder.addCase(begin_reset_password.pending, (state) => {
      state.resetPassword.loading = true
    })
    builder.addCase(begin_reset_password.fulfilled, (state, action) => {
      state.resetPassword.flow_id = action.payload?.response?.flow_id
      state.resetPassword.identifier = action.payload?.requestPayload?.identifier
      state.resetPassword.loading = false
    })
    builder.addCase(begin_reset_password.rejected, (state) => {
      state.resetPassword.loading = false
    })

    // begin reset password
    builder.addCase(confirm_reset_password.pending, (state) => {
      state.confirmResetPassword.loading = true
    })
    builder.addCase(confirm_reset_password.fulfilled, (state) => {
      state.confirmResetPassword.loading = false
    })
    builder.addCase(confirm_reset_password.rejected, (state) => {
      state.confirmResetPassword.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const { selectIdentity, seedUserDetails, clearSignupUserDetails, removeBroadCastMessage } =
  authSlice.actions

export default authSlice.reducer
