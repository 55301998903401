import { createContext, useContext, useRef } from 'react'

// Create a new context
const ComponentRefsContext = createContext(null)

// Custom hook to access the context value
// eslint-disable-next-line react-refresh/only-export-components
export const useTourComponentRefs = () => {
  return useContext(ComponentRefsContext)
}

// Provider component that manages component refs
const TourRefsProvider = ({ children }) => {
  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const ref3 = useRef(null)
  const ref4 = useRef(null)
  const ref5 = useRef(null)
  const ref6 = useRef(null)

  // Expose the refs via context value
  const componentRefs = {
    ref1,
    ref2,
    ref3,
    ref4,
    ref5,
    ref6
  }

  return (
    <ComponentRefsContext.Provider value={componentRefs}>{children}</ComponentRefsContext.Provider>
  )
}

export default TourRefsProvider
