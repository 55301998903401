import { Button, Card, Flex, Tooltip, Typography } from 'antd'
import {
  ArrowDownIcon,
  HorizontalRule,
  QuestionMarkIcon,
  SidebarLogoExpanded,
  SiderBarlogoCollapsed
} from 'assets/svg'
import React, { useEffect, useMemo, useRef, useState, useTransition } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { sidebarWidgetData } from './Data'
import SidebarWidgetDialog from '../sidebar-widget-dialog'
import { useUserDetails } from 'context/UserDetailsProvider'
import { roles } from '../../../constants'
import './Sidebar.scss'

// --> Created-by : thakkarshrey10
// --> Created At : 18/04/2024

// The purpose of this component is to navigate through pages.
// appAdminSidebarUtils is where all the sidebar data is present.
// collapsed is the state we are taking from the Main.layout to manage the collapsed and open state of sidebar.
// The button is absolutely positioned.
// NavigationList component is where the list of our sidebar data is displayed.
// GetCustomTooltipConditionally is the layout is where the tooltip is rendered conditionally. When the sidebar is collapsed tooltip will be displayed.

function Sidebar({ collapsed, menuGroup }) {
  console.log('menuGroup', menuGroup)
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* location dependencies */
  const location = useLocation()
  /* location dependencies */

  /* navigation dependencies */
  const navigate = useNavigate()
  /* navigation dependencies */

  /* authentication dependencies */
  const { selectedRole } = useUserDetails()
  /* authentication dependencies */

  /* ref dependencies */
  const indicatorRef = useRef(null)
  const listItemTagRef = useRef(null)
  const menuGroupTitleRef = useRef(null)
  /* ref dependencies */

  /* state dependencies */
  const [showSidebarWidget, setShowSidebarWidget] = useState(false)
  const [sidebarWidgetIndex, setSidebarWidgetIndex] = useState(undefined)
  /* state dependencies */

  /* tab index dependencies */
  const [currentTabIndex, setCurrentTabIndex] = useState(0)
  /* tab index dependencies */

  /* transition dependencies */
  const [, startTransition] = useTransition()
  /* transition dependencies */

  /* dialog dependencies */
  const [sidebarWidgetDialogOpen, setSidebarWidgetDialogOpen] = useState(false)

  const handleOpenSidebarWidgetDialog = (widgetIndex) => {
    setSidebarWidgetIndex(widgetIndex)
    setSidebarWidgetDialogOpen(true)
  }

  const handleCloseSidebarWidgetDialog = () => {
    setSidebarWidgetIndex(undefined)
    setSidebarWidgetDialogOpen(false)
  }

  const sidebarWidgetProps = useMemo(
    () => ({
      open: sidebarWidgetDialogOpen,
      onClose: handleCloseSidebarWidgetDialog,
      sidebarWidgetIndex
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sidebarWidgetDialogOpen]
  )

  /* dialog dependencies */

  useEffect(() => {
    if (menuGroup && menuGroup !== undefined) {
      startTransition(() => {
        const tempMenuTab = [...menuGroup]
        const tabIndex = menuGroup.findIndex((element) => element?.url === location.pathname)
        setCurrentTabIndex(tabIndex)
        if (tempMenuTab[tabIndex]?.url) navigate(tempMenuTab[tabIndex]?.url, { replace: true })
      })
    }
    if (listItemTagRef.current) {
      indicatorRef.current.style.top = listItemTagRef?.current?.offsetTop + 'px'
      indicatorRef.current.style.width = listItemTagRef?.current?.offsetWidth + 'px'
      indicatorRef.current.style.height = listItemTagRef?.current.offsetHeight + 'px'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, currentTabIndex])

  useEffect(() => {
    let indicatorRefCurrent = indicatorRef?.current
    indicatorRefCurrent && collapsed
      ? (indicatorRefCurrent.style.width = `${50}px`)
      : (indicatorRef.current.style.width = `${199}px`)
  }, [collapsed])

  return (
    <>
      <div className={`sidebar-container ${collapsed ? 'sidebar-collapsed' : 'sidebar-open'}`}>
        <Flex gap="1rem" vertical>
          <Flex gap="1rem" align="center" justify="center">
            {collapsed ? <SiderBarlogoCollapsed /> : <SidebarLogoExpanded />}
          </Flex>
          <div style={{ visibility: collapsed ? 'hidden' : 'visible' }}>
            <HorizontalRule />
          </div>
          <div className="sidebar-wrapper">
            <ul className="navigation-list">
              {menuGroup?.map((element, index) => {
                return (
                  <React.Fragment key={element.id}>
                    <NavigationList
                      myRef={element?.ref}
                      element={element}
                      collapsed={collapsed}
                      currentTabIndex={currentTabIndex}
                      setCurrentTabIndex={setCurrentTabIndex}
                      location={location}
                      indicatorRef={indicatorRef}
                      listItemTagRef={listItemTagRef}
                      menuGroupTitleRef={menuGroupTitleRef}
                      index={index}
                      navigate={navigate}
                    />
                  </React.Fragment>
                )
              })}
            </ul>
            <div className="indicator" ref={indicatorRef}>
              <div className={`indicator-container`}></div>
            </div>
          </div>
        </Flex>
        {![roles.superAdmin].includes(selectedRole) && !collapsed && (
          <div className="sidebar-widget-container">
            <Flex
              className={`sidebar-widget-card ${showSidebarWidget ? 'show-sidebar-widget-card' : 'hide-sidebar-widget-card'}`}
              vertical
              gap={'0.5rem'}
              align="center"
            >
              {sidebarWidgetData?.map((item, index) => (
                <Button
                  className="sidebar-widget-btn"
                  key={index}
                  onClick={() => handleOpenSidebarWidgetDialog(index)}
                >
                  {item?.label}
                </Button>
              ))}
            </Flex>
            <Card
              className="sidebar-widget"
              onClick={() => setShowSidebarWidget((prevState) => !prevState)}
            >
              <Flex align="center" justify="space-between" gap={'0.5rem'}>
                <div className="sidebar-widget-header-icon">
                  <QuestionMarkIcon />
                </div>
                <Typography.Title level={3} style={{ color: 'var(--pure-white)', fontWeight: 500 }}>
                  {t('SidebarWidget.NeedHelp')}
                </Typography.Title>
                <div className={`${showSidebarWidget ? 'toggle-left' : 'toggle-right'}`}>
                  <ArrowDownIcon color="var(--pure-white)" />
                </div>
              </Flex>
            </Card>
          </div>
        )}
      </div>
      <SidebarWidgetDialog {...sidebarWidgetProps} />
    </>
  )
}

function NavigationList({
  element,
  collapsed,
  currentTabIndex,
  setCurrentTabIndex,
  indicatorRef,
  listItemTagRef,
  index,
  navigate,
  myRef
}) {
  const handleNavigationClicked = (event, _element, tempIndex) => {
    setCurrentTabIndex(tempIndex)
    indicatorRef.current.style.top = event?.target?.offsetTop + 'px'
    indicatorRef.current.style.width = event.target?.offsetWidth + 'px'
    indicatorRef.current.style.height = event.target?.offsetHeight + 'px'
    navigate(_element.url, { replace: true })
  }

  return (
    <li
      key={element.id}
      className={`${
        element.isSubMenu ? 'menu-group-title' : 'navigation-item-list'
      } ${collapsed && 'navigation-item-list-collapsed'} ${index === currentTabIndex ? 'navigation-item-list-primary' : 'navigation-item-list-white'}`}
      ref={index === currentTabIndex ? listItemTagRef : null}
    >
      <GetCustomTooltipConditionally collapsed={collapsed} title={element.title}>
        <div
          className={`${index === currentTabIndex ? 'navigation-item-link-white-bg' : 'navigation-item-link-primary-bg'} navigation-item-link`}
          key={element.id}
          ref={myRef}
          onClick={(event) => handleNavigationClicked(event, element, index)}
        >
          {!element.isSubMenu ? (
            <div className={element.isSubMenu ? 'navigation-icon-none' : 'navigation-icon'}>
              <element.icon color="var(--pure-white)" />
            </div>
          ) : (
            ''
          )}
          <div className={`navigation-label ${collapsed ? 'navigation-label-hidden' : ''}`}>
            {element.title}
          </div>
        </div>
      </GetCustomTooltipConditionally>
    </li>
  )
}

const GetCustomTooltipConditionally = ({ children, collapsed, title }) => {
  return collapsed ? (
    <Tooltip title={title} placement="right" color={'var(--midnight-gray)'}>
      {children}
    </Tooltip>
  ) : (
    children
  )
}

export default Sidebar
