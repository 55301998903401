import { Button, Col, Dropdown, Row } from 'antd'
import {
  ArrowDownIcon,
  AuthCarouselImage01,
  AuthCarouselImage02,
  AuthLogo,
  LanguageIcon
} from '../../../assets/svg'
import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import './AuthLayout.scss'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'

// --> Created-by : thakkarshrey10 and reactive-karan
// --> Dated : 16/04/2024

// The purpose of this component is to provide a common wrapper layout for all the auth pages
// Common layout contains a carousel component with images
// Also some styling is done for the children

const AuthLayout = ({ languageButton = true }) => {
  const location = useLocation()
  const navigate = useNavigate()
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* float button dependencies */
  const items = [
    {
      key: '1',
      label: t('SignIn.English')
    },
    {
      key: '2',
      label: t('SignIn.French')
    }
  ]
  /* float button dependencies */

  return (
    <div className="auth-wrapper">
      <Row className="auth-container">
        <Col xs={0} sm={0} lg={11} className="auth-carousel-container">
          <AuthLogo />
          <CustomCarousel speed={5000}>
            <AuthCarouselImage01 />
            <AuthCarouselImage02 />
          </CustomCarousel>
        </Col>
        <Col xs={24} sm={24} lg={13} className="auth-content-container">
          <div className="content-wrapper-container">
            {location.pathname.includes(AppRoutingConfig.APP_URL_VERIFY_YOUR_IDENTITY) && (
              <div className="content-back">
                <Button
                  size="large"
                  type="primary"
                  className="add-member-manually-btn"
                  onClick={() => navigate(-1)}
                >
                  <ArrowDownIcon iconStyle="add-member-manually-btn-icon" />
                  <p> {t('common.caseStepper.back')}</p>
                </Button>
              </div>
            )}
            <div className="content-container">
              <Outlet />
            </div>
          </div>
        </Col>
      </Row>

      {languageButton && (
        <>
          <div className="float-button-container">
            <Dropdown menu={{ items }} placement="topRight">
              <Button
                icon={<LanguageIcon />}
                shape="circle"
                type="primary"
                style={{ boxShadow: 'var(--default-box-shadow)', width: '40px', aspectRatio: 1 }}
              />
            </Dropdown>
          </div>
        </>
      )}
    </div>
  )
}

function CarouselComponent({ carouselElement, carouselIndex, activeImage }) {
  return (
    <div
      className={`carousel-card ${carouselIndex === activeImage ? 'carousel-card-active' : ''}`}
      key={carouselIndex}
    >
      {carouselElement}
    </div>
  )
}

function IndicatorComponent({ carouselIndex, activeImage, setActiveImage }) {
  return (
    <div
      key={carouselIndex}
      onClick={() => setActiveImage(carouselIndex)}
      className={`carousel-indicator ${carouselIndex === activeImage ? 'carousel-indicator-active' : ''}`}
      data-testid={`carousel-indicator-${carouselIndex}`}
    ></div>
  )
}

function CustomCarousel({ children, speed = 2000 }) {
  /* carousel dependencies */
  const [activeImage, setActiveImage] = useState(0)
  /* carousel dependencies */

  const carouselElements = React.Children.toArray(children)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setActiveImage((prevValue) => (prevValue === carouselElements.length - 1 ? 0 : prevValue + 1))
    }, speed)

    return () => {
      clearTimeout(timeout)
    }
  }, [carouselElements.length, activeImage, setActiveImage, speed])

  return (
    <>
      {carouselElements?.map((carouselElement, index) => {
        return (
          <CarouselComponent
            key={index}
            carouselElement={carouselElement}
            carouselIndex={index}
            activeImage={activeImage}
          />
        )
      })}
      <div className="carousel-indicator-container">
        {carouselElements?.map((_, index) => {
          return (
            <IndicatorComponent
              key={index}
              carouselIndex={index}
              activeImage={activeImage}
              setActiveImage={setActiveImage}
            />
          )
        })}
      </div>
    </>
  )
}

export default AuthLayout
