import { Flex, Modal, Typography } from 'antd'
import { sidebarWidgetData } from '../sidebar/Data'

const SidebarWidgetDialog = ({ open, onClose, sidebarWidgetIndex }) => {
  /* dialog close dependencies */
  const handleCloseDialog = () => {
    onClose()
  }
  /* dialog close dependencies */

  return (
    <Modal
      destroyOnClose
      centered
      footer={false}
      open={open}
      onCancel={handleCloseDialog}
      width={1000}
    >
      <Flex vertical gap="1rem">
        <Flex justify="center" style={{ marginTop: 50 }}>
          <Typography.Title level={1} style={{ textDecoration: 'underline' }}>
            {sidebarWidgetData?.[sidebarWidgetIndex]?.title}
          </Typography.Title>
        </Flex>
        {sidebarWidgetData?.[sidebarWidgetIndex]?.component}
      </Flex>
    </Modal>
  )
}

export default SidebarWidgetDialog
