import { createSlice } from '@reduxjs/toolkit'
import {
  get_physicians_list,
  deactivate_physician,
  update_physician_plan,
  change_physician_password
} from '../services/physiciansService'

const initialState = {
  physicianStep: 0,
  getPhysiciansList: {
    data: null,
    loading: false
  },
  deactivatePhysican: {
    loading: false
  },
  updatePhysicianPlan: {
    loading: false
  },
  updatePhysicianPassword: {
    loading: false
  }
}
export const physiciansSlice = createSlice({
  name: 'physicians',
  initialState,
  reducers: {
    navigateToNextForm: (state, action) => {
      state.physicianStep = action.payload
    }
  },
  extraReducers: (builder) => {
    // get Profession
    builder.addCase(get_physicians_list.pending, (state) => {
      state.getPhysiciansList.loading = true
    })
    builder.addCase(get_physicians_list.fulfilled, (state, action) => {
      state.getPhysiciansList.data = action.payload.response
      state.getPhysiciansList.loading = false
    })
    builder.addCase(get_physicians_list.rejected, (state) => {
      state.getPhysiciansList.loading = false
    })

    // deactivate physicians
    builder.addCase(deactivate_physician.pending, (state) => {
      state.deactivatePhysican.loading = true
    })
    builder.addCase(deactivate_physician.fulfilled, (state) => {
      state.deactivatePhysican.loading = false
    })
    builder.addCase(deactivate_physician.rejected, (state) => {
      state.deactivatePhysican.loading = false
    })

    // Update Physician Plan
    builder.addCase(update_physician_plan.pending, (state) => {
      state.updatePhysicianPlan.loading = true
    })
    builder.addCase(update_physician_plan.fulfilled, (state) => {
      state.updatePhysicianPlan.loading = false
    })
    builder.addCase(update_physician_plan.rejected, (state) => {
      state.updatePhysicianPlan.loading = false
    })

    // Update Physician Password
    builder.addCase(change_physician_password.pending, (state) => {
      state.updatePhysicianPassword.loading = true
    })
    builder.addCase(change_physician_password.fulfilled, (state) => {
      state.updatePhysicianPassword.loading = false
    })
    builder.addCase(change_physician_password.rejected, (state) => {
      state.updatePhysicianPassword.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const { navigateToNextForm } = physiciansSlice.actions

export default physiciansSlice.reducer
