import { createSlice } from '@reduxjs/toolkit'

export const activeProfileSlice = createSlice({
  name: 'activeProfile',
  initialState: {
    activeProfile: null
  },
  reducers: {
    setActiveProfile: (state, action) => {
      state.activeProfile = action.payload
    }
  }
})

export const { setActiveProfile } = activeProfileSlice.actions

export default activeProfileSlice.reducer
