import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'

export const authorEditorSettingsSidebarUtils = (t) => {
  let menuGroup = [
    {
      id: 'profile-information',
      title: t('AppModuleNames.ProfileInformation'),
      url: AppRoutingConfig.PROFILE_INFORMATION
    },
    {
      id: 'professional-information',
      title: t('AppModuleNames.ProfessionalInformation'),
      url: AppRoutingConfig.PROFESSIONAL_INFORMATION
    }
  ]

  return menuGroup
}
