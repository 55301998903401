import { CloseOutlined } from '@ant-design/icons'
import { Badge, Button, Divider, Flex, Typography } from 'antd'
import { roles } from '../../../constants'
import { useUserDetails } from 'context/UserDetailsProvider'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNotify } from 'context/NotificationProvider'
import { NotificationsWidgetService } from '../../../redux/services'

const NotificationWidgetHeader = ({ showContent, onClose, notificationCount }) => {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* authentication dependencies */
  const { selectedRole } = useUserDetails()
  /* authentication dependencies */

  /* redux dependencies */
  const dispatch = useDispatch()
  /* redux dependencies */

  /* notification dependencies */
  const { showNotification } = useNotify()
  /* notification dependencies */

  /* handle mark read all notifications */
  const handleReadAllNotifications = () => {
    const updateNotificationObject = {
      mark_all_as_read: true
    }
    dispatch(
      NotificationsWidgetService.update_notification({
        payload: updateNotificationObject,
        showNotification,
        closeNotificationWidget: onClose
      })
    )
  }
  /* handle mark read all notifications */
  return (
    <>
      <Flex align="center" justify="space-between" style={{ padding: '20px 20px 10px 20px' }}>
        <Flex align="center" gap={'0.5rem'}>
          <Typography.Title level={2}>{t('Notifications.Notifications')}</Typography.Title>
          {[roles.superAdmin, roles.patient].includes(selectedRole) && notificationCount > 0 && (
            <Badge count={notificationCount} />
          )}
        </Flex>
        <Flex align="center" gap={'0.5rem'}>
          <Typography.Link onClick={handleReadAllNotifications}>
            {t('Notifications.MarkAllAsRead')}
          </Typography.Link>
          {!showContent && (
            <Button type="text" icon={<CloseOutlined />} onClick={() => onClose()} />
          )}
        </Flex>
      </Flex>
      {showContent && <Divider />}
    </>
  )
}

export default NotificationWidgetHeader
