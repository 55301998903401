import { Button, Flex, Spin, Typography } from 'antd'
import RichTextEditor from 'components/common/rich-text-editor/RichTextEditor'
import { useNotify } from 'context/NotificationProvider'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ConfigurePoliciesService } from '../../../../../../../redux/services'
import { updatePrivacyPolicy } from '../../../../../../../redux/reducer/configurePoliciesReducer'

const policy_type = 'COOKIE_POLICY'

const CookiePolicy = ({ activeTab, isSideBarWidget = false }) => {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* dispatch dependencies */
  const dispatch = useDispatch()
  const { getPolicy, updatePolicy } = useSelector((state) => state.configurePolicies)
  /* dispatch dependencies */

  /* notification dependencies */
  const { showNotification } = useNotify()
  /* notification dependencies */

  /* editor dependencies */
  const [showEditorForEdit, setShowEditorForEdit] = useState(false)
  /* editor dependencies */

  /* initial dependencies */
  const handleGetData = () => {
    const payload = {
      type: policy_type
    }

    dispatch(ConfigurePoliciesService.get_policy_list({ payload, showNotification }))
  }

  useEffect(() => {
    if (activeTab == '4' || isSideBarWidget) handleGetData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])
  /* initial dependencies */

  const setEditorValue = (content) => dispatch(updatePrivacyPolicy({ content }))

  /* handle save privacy policy */
  const handleSavePrivacyPolicy = () => {
    const payload = {
      type: policy_type,
      content: getPolicy?.data?.content
    }
    dispatch(
      ConfigurePoliciesService.update_policy({
        payload,
        showNotification,
        setShowEditorForEdit,
        handleGetData
      })
    )
  }
  /* handle save privacy policy */

  return (
    <Spin spinning={getPolicy?.loading}>
      {getPolicy?.data?.content && getPolicy?.data?.content !== '' && !showEditorForEdit ? (
        <Flex gap="1rem" vertical>
          {!isSideBarWidget && (
            <div>
              <Button size="large" onClick={() => setShowEditorForEdit(true)}>
                {getPolicy?.data?.content && getPolicy?.data?.content !== ''
                  ? t('ConfigurePolicies.EditCookiePolicy')
                  : t('ConfigurePolicies.AddCookiePolicy')}
              </Button>
            </div>
          )}
          <div dangerouslySetInnerHTML={{ __html: getPolicy?.data?.content }} />
        </Flex>
      ) : (
        !isSideBarWidget &&
        !getPolicy?.loading && (
          <Flex gap="1rem" vertical>
            <Flex gap="0.5rem" vertical>
              <Typography.Title level={3}>
                {t('ConfigurePolicies.EditCookiePolicy')}
              </Typography.Title>
              <RichTextEditor
                value={getPolicy?.data?.content}
                setValue={setEditorValue}
                loading={updatePolicy?.loading}
              />
              <Flex gap="1rem">
                <Button size="large" onClick={() => handleSavePrivacyPolicy()}>
                  {t('CommonUtils.Save')}
                </Button>
                <Button size="large" type="primary" onClick={() => setShowEditorForEdit(false)}>
                  {t('CommonUtils.Back')}
                </Button>
              </Flex>
            </Flex>
          </Flex>
        )
      )}
    </Spin>
  )
}

export default CookiePolicy
