import { Drawer, Tabs } from 'antd'
// import ConditionalWrapper from '../conditional-wrapper'
import NotificationWidgetHeader from './NotificationWidgetHeader'
import { notificationsTabsArr } from './notificationWidgetTabs'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNotify } from 'context/NotificationProvider'
import { NotificationsWidgetService } from '../../../redux/services'
import { useUserDetails } from 'context/UserDetailsProvider'
import { roles } from '../../../constants'
import NotificationList from './NotificationList'
import { clearNotificationsData } from '../../../redux/reducer/notificationsWidgetReducer'
import './NotificationWidget.scss'

const NotificationWidget = ({ isCollapsed, setCollapsed, showContent }) => {
  /* redux dependencies */
  const dispatch = useDispatch()
  const { getNotificationsList } = useSelector((state) => state.notificationsWidget)
  /* redux dependencies */

  /* notification dependencies */
  const { showNotification } = useNotify()
  /* notification dependencies */

  /* authentication dependencies */
  const { selectedRole } = useUserDetails()
  /* authentication dependencies */

  /* state dependencies */
  const [notifictionsType, setNotificationsType] = useState('All')
  /* state dependencies */

  /* on notifications tabs changes*/
  const onNotificationsChange = (key) => {
    dispatch(clearNotificationsData())
    setNotificationsType(key)
  }
  /* on notifications tabs changes*/

  /*useEffect hook to fetch initial data when the component mounts */
  useEffect(() => {
    if (isCollapsed) {
      const getNotificationsListPayload = {
        filterType: notifictionsType === 'All' ? '' : notifictionsType,
        page: 1,
        pageSize: 1000
      }
      dispatch(
        NotificationsWidgetService.get_notifications_list({
          payload: getNotificationsListPayload,
          showNotification
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCollapsed, notifictionsType])
  /*useEffect hook to fetch initial data when the component mounts */

  return (
    <div
      className={`notification-widget-container ${isCollapsed ? 'show-notification-widget-card' : 'hide-notification-widget-card'}`}
    >
      <ConditionalWrapper
        isCollapsed={isCollapsed}
        setCollapsed={setCollapsed}
        showContent={showContent}
        notificationCount={getNotificationsList?.notificationListData?.unreadCount}
      >
        {![roles.superAdmin, roles.patient].includes(selectedRole) ? (
          <Tabs
            defaultActiveKey="All"
            items={notificationsTabsArr(notifictionsType, getNotificationsList)}
            onChange={onNotificationsChange}
            className={`notification-tab-style ${showContent && 'notification-list-style'}`}
            indicator={{
              size: (origin) => origin - 20,
              align: 'start'
            }}
          />
        ) : (
          <NotificationList
            notifictionsType={notifictionsType}
            listData={getNotificationsList?.notificationListData?.data}
            loading={getNotificationsList?.loading}
            className={
              [roles.superAdmin, roles.patient].includes(selectedRole) &&
              showContent &&
              'notification-list-style'
            }
          />
        )}
      </ConditionalWrapper>
    </div>
  )
}

const ConditionalWrapper = ({
  children,
  isCollapsed,
  setCollapsed,
  showContent,
  notificationCount
}) => {
  /* redux dependencies */
  const dispatch = useDispatch()
  /* redux dependencies */

  /* handleNotification Close functionality */
  const onClose = () => {
    dispatch(clearNotificationsData())
    setCollapsed((prevState) => !prevState)
  }
  /* handleNotification Close functionality */

  return !showContent ? (
    <Drawer
      title={
        <NotificationWidgetHeader
          showContent={showContent}
          onClose={onClose}
          notificationCount={notificationCount}
        />
      }
      placement={'right'}
      onClose={onClose}
      open={isCollapsed}
      closable={false}
    >
      {children}
    </Drawer>
  ) : (
    <>
      <NotificationWidgetHeader
        showContent={showContent}
        notificationCount={notificationCount}
        onClose={onClose}
      />
      {children}
    </>
  )
}

export default NotificationWidget
