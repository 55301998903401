import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get question library list
export const get_questionnaire_list = createAsyncThunk(
  'questionLibrary/get_questionnaire_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(
        `v1/admin/questionnaire/get-all-grouped?page=${payload.page}&pageSize=${payload.pageSize}`
      )
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get questionnaire
export const get_questionnaire = createAsyncThunk(
  'questionLibrary/get_questionnaire',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/questionnaire/get-all`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update questionnaire
export const update_questionnaire = createAsyncThunk(
  'questionLibrary/update_questionnaire',
  async (
    { payload, showNotification, handleCloseDialog, handleGetQuestionnaireData },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(`v1/admin/questionnaire/crud`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        handleGetQuestionnaireData()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
