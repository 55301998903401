import { translate } from 'i18n/i18n'

export const filterType = [
  {
    label: translate('BroadcastWidget.General'),
    value: 'General'
  },
  {
    label: translate('BroadcastWidget.Specialties'),
    value: 'Specialties'
  }
]
