import { createSlice } from '@reduxjs/toolkit'
import { get_account_settings, update_account_settings } from '../services/accountSettingsService'
import { decryptData, encryptData } from 'assets/config/AppEncryptDecryptConfig'

export const accountSettingsSlice = createSlice({
  name: 'accountSettings',
  initialState: {
    accountSettings: {
      loading: false,
      account_settings_info: {}
    },
    updateAccountSettings: {
      loading: false
    }
  },
  extraReducers: (builder) => {
    // Get Account Settings Info
    builder.addCase(get_account_settings.pending, (state) => {
      state.accountSettings.loading = true
    })
    builder.addCase(get_account_settings.fulfilled, (state, action) => {
      state.accountSettings.loading = false
      state.accountSettings.account_settings_info = action.payload?.response
    })
    builder.addCase(get_account_settings.rejected, (state) => {
      state.accountSettings.loading = false
    })

    // Update Account Settings Info
    builder.addCase(update_account_settings.pending, (state) => {
      state.updateAccountSettings.loading = true
    })
    builder.addCase(update_account_settings.fulfilled, (state) => {
      state.updateAccountSettings.loading = false

      const user_data = decryptData('user')
      const modified_user_data = { ...user_data }
      if (modified_user_data) modified_user_data.is_account_settings_updated = true
      encryptData('user', modified_user_data)
    })
    builder.addCase(update_account_settings.rejected, (state) => {
      state.updateAccountSettings.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
// export const {} = accountSettingsSlice.actions

export default accountSettingsSlice.reducer
