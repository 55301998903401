import { Outlet } from 'react-router-dom'
import './BlueShadeLayout.scss'

const BlueShadeLayout = () => {
  return (
    <div className="blue-shage-layout-container">
      <Outlet />
    </div>
  )
}

export default BlueShadeLayout
