import { createSlice } from '@reduxjs/toolkit'
import { get_hcp_list } from '../services/hcpService'

export const hcpSlice = createSlice({
  name: 'hcp',
  initialState: {
    getHcpList: {
      data: null,
      loading: false
    }
  },
  extraReducers: (builder) => {
    // get hcp
    builder.addCase(get_hcp_list.pending, (state) => {
      state.getHcpList.loading = true
    })
    builder.addCase(get_hcp_list.fulfilled, (state, action) => {
      state.getHcpList.data = action.payload.response
      state.getHcpList.loading = false
    })
    builder.addCase(get_hcp_list.rejected, (state) => {
      state.getHcpList.loading = false
    })
  }
})

// Action creators are generated for each case reducer function

export default hcpSlice.reducer
