import { createSlice } from '@reduxjs/toolkit'

export const allUsersSlice = createSlice({
  name: 'allUsers',
  initialState: {
    allUsersTab: 1
  },
  reducers: {
    navigateToNextTab: (state, action) => {
      state.allUsersTab = action.payload
    }
  }
})

export const { navigateToNextTab } = allUsersSlice.actions

export default allUsersSlice.reducer
