import { createSlice } from '@reduxjs/toolkit'
import { get_patient_profile_info, get_patients_list } from '../services/patientsService'

export const patientsSlice = createSlice({
  name: 'patients',
  initialState: {
    getPatientsList: {
      data: null,
      loading: false
    },
    getPatientProfileInfo: {
      data: null,
      loading: false
    }
  },
  extraReducers: (builder) => {
    // get patients
    builder.addCase(get_patients_list.pending, (state) => {
      state.getPatientsList.loading = true
    })
    builder.addCase(get_patients_list.fulfilled, (state, action) => {
      state.getPatientsList.data = action?.payload?.response
      state.getPatientsList.loading = false
    })
    builder.addCase(get_patients_list.rejected, (state) => {
      state.getPatientsList.loading = false
    })

    // get patient profile info
    builder.addCase(get_patient_profile_info.pending, (state) => {
      state.getPatientProfileInfo.loading = true
    })
    builder.addCase(get_patient_profile_info.fulfilled, (state, action) => {
      state.getPatientProfileInfo.data = action?.payload?.response
      state.getPatientProfileInfo.loading = false
    })
    builder.addCase(get_patient_profile_info.rejected, (state) => {
      state.getPatientProfileInfo.loading = false
    })
  }
})

// Action creators are generated for each case reducer function

export default patientsSlice.reducer
