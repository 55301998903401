import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get billing info
export const get_billing_info = createAsyncThunk(
  'billingInfo/get_billing_info',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/get/billing_info`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get community billing info
export const get_community_billing_info = createAsyncThunk(
  'billingInfo/get_community_billing_info',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/get-billing-info`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update billing info
export const update_billing_info = createAsyncThunk(
  'billingInfo/update_billing_info',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/update/billing_info`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update community billing info
export const update_community_billing_info = createAsyncThunk(
  'billingInfo/update_community_billing_info',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/update-billing-info`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

export const connect_to_stripe = createAsyncThunk(
  'billingInfo/connect_to_stripe',
  async ({ payloadData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/payment/add/credit-card-info`, payloadData)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response.message
        })
        return response.data
      } else {
        showNotification({
          type: 'error',
          message: 'Failed to add credit card information.'
        })
        return rejectWithValue('Failed to add credit card information.')
      }
    } catch (error) {
      console.error('Error adding credit card info:', error)
      showNotification({
        type: 'error',
        message: 'Error adding credit card information: ' + error.message
      })
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
export const connect_to_stripe_iban = createAsyncThunk(
  'billingInfo/connect_to_stripe_iban',
  async ({ payloadData, showNotification, setLoading }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/payment/add/iban-info`, payloadData)
      if (response.status === 0) {
        return response.data
      } else {
        showNotification({
          type: 'error',
          message: 'Failed to add payment information.'
        })
        setLoading(false)
        return
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: 'Error adding payment information: ' + error.message
      })
      setLoading(false)
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

export const connect_community_to_stripe_iban = createAsyncThunk(
  'billingInfo/connect_community_to_stripe_iban',
  async ({ payloadData, showNotification, setLoading }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/community/add-iban-info`, payloadData)
      if (response.status === 0) {
        return response.data
      } else {
        showNotification({
          type: 'error',
          message: 'Failed to add payment information.'
        })
        setLoading(false)
        return
      }
    } catch (error) {
      showNotification({
        type: 'error',
        message: 'Error adding payment information: ' + error.message
      })
      setLoading(false)
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

export const add_credit_card_info = createAsyncThunk(
  'billingInfo/add_credit_card_info',
  async ({ payloadData, showNotification, setLoading, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/payment/add/credit-card-info`, payloadData)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseDialog()
        return response.data
      } else {
        showNotification({
          type: 'error',
          message: 'Failed to add payment information.'
        })
        setLoading(false)
        return
      }
    } catch (error) {
      console.error('Error adding payment info:', error)
      showNotification({
        type: 'error',
        message: 'Error adding payment information: ' + error.message
      })
      setLoading(false)
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
