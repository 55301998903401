import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// add broadcast message
export const add_broadcast_message = createAsyncThunk(
  'broadCast/add_broadcast_message',
  async ({ payload, showNotification, handleCloseBroadCast }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/enter-broadcast-msg`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        handleCloseBroadCast()
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
