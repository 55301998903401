import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import ErrorBoundary from 'components/common/error-boundary'
import AuthLayout from 'components/layouts/auth-layout'
import UserDetailsProvider from 'context/UserDetailsProvider'
import { Navigate } from 'react-router-dom'
import * as lazy from './lazyComponents'
import AuthSecondaryLayout from 'components/layouts/auth-secondary-layout'

const CommonWrapper = ({ Layout }) => {
  return (
    <UserDetailsProvider>
      <Layout />
    </UserDetailsProvider>
  )
}

export const auth_routes = [
  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: <CommonWrapper Layout={AuthLayout} />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Navigate to={AppRoutingConfig.APP_URL_LOGIN} />
      },
      {
        path: AppRoutingConfig.APP_URL_LOGIN,
        element: <lazy.Login />
      },
      {
        path: AppRoutingConfig.APP_URL_SIGN_UP,
        element: <lazy.Signup />
      },
      {
        path: AppRoutingConfig.APP_URL_SIGN_UP_WITH_RPPS,
        element: <lazy.SignupWithRpps />
      },
      {
        path: AppRoutingConfig.APP_URL_CREATE_PASSWORD,
        element: <lazy.CreatePassword />
      },
      {
        path: AppRoutingConfig.APP_URL_VERIFY_YOUR_IDENTITY,
        element: <lazy.VerifyYourIdentity />
      },
      {
        path: AppRoutingConfig.APP_URL_SELECT_SIGNUP_METHOD,
        element: <lazy.SelectSignupMethod />
      }
    ]
  },
  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: <CommonWrapper Layout={AuthSecondaryLayout} />,
    children: [
      {
        path: AppRoutingConfig.APP_URL_ACCOUNT_INFORMATION,
        element: <lazy.AccountInformation />
      },
      {
        path: AppRoutingConfig.APP_URL_PATIENT_ACCOUNT_INFORMATION,
        element: <lazy.PatientAccountInformation />
      },
      {
        path: AppRoutingConfig.APP_URL_LOGIN_CONFIRMATION,
        element: <lazy.LoginConfirmaiton />
      },
      {
        path: AppRoutingConfig.APP_URL_SIGNED_UP_CONFIRMATION,
        element: <lazy.SignedUpConfirmaiton />
      }
    ]
  },
  {
    path: AppRoutingConfig.APP_URL_CHOOSE_YOUR_IDENTITY,
    element: <lazy.ChooseYourIdentity />
  }
]
