import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'

export const physicianSettingsSidebarUtils = (t) => {
  let menuGroup = [
    {
      id: 'profile-information',
      title: t('AppModuleNames.ProfileInformation'),
      url: AppRoutingConfig.PROFILE_INFORMATION
    },
    {
      id: 'professional-information',
      title: t('AppModuleNames.ProfessionalInformation'),
      url: AppRoutingConfig.PROFESSIONAL_INFORMATION
    },
    {
      id: 'account-settings',
      title: t('AppModuleNames.AccountSettings'),
      url: AppRoutingConfig.ACCOUNT_SETTINGS
    },
    {
      id: 'request-management',
      title: t('AppModuleNames.RequestsManagement'),
      url: AppRoutingConfig.REQUEST_MANAGEMENT
    },
    {
      id: 'notifications',
      title: t('AppModuleNames.Notifications'),
      url: AppRoutingConfig.NOTIFICATIONS
    },
    {
      id: 'billing-info',
      title: t('AppModuleNames.BillingInfo'),
      url: AppRoutingConfig.BILLING_INFO
    }
    // {
    //   id: 'storage-and-data',
    //   title: t('AppModuleNames.StorageAndData'),
    //   children: [
    //     {
    //       id: 'test-users',
    //       title: 'Test Users',
    //       url: AppRoutingConfig.TEST_USERS
    //     }
    //   ]
    // },
    // {
    //   id: 'storage',
    //   title: 'Storage',
    //   children: [
    //     {
    //       id: 'test-physicians',
    //       title: 'Test Physicians',
    //       url: AppRoutingConfig.TEST_PHYSICIANS
    //     }
    //   ]
    // }
  ]

  return menuGroup
}
