import { notification } from 'antd'
import { createContext, useContext } from 'react'

export const NotificationContext = createContext()

const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification()

  const showNotification = ({ type, message, description, ...props }) => {
    api[type]({
      message,
      description,
      ...props
    })
  }
  return (
    <NotificationContext.Provider value={{ showNotification }}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  )
}

// eslint-disable-next-line react-refresh/only-export-components
export const useNotify = () => {
  return useContext(NotificationContext)
}

export default NotificationProvider
