import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import { Navigate } from 'react-router-dom'
import { useUserDetails } from 'context/UserDetailsProvider'

const RequireAuth = ({ children, allowedRoles }) => {
  const { selectedRole, isAuthenticated } = useUserDetails()
  const userHasRequiredRole = allowedRoles.includes(selectedRole)

  // const isAuthenticated = true

  if (isAuthenticated && !userHasRequiredRole) {
    return <Navigate to={AppRoutingConfig.APP_URL_NOT_PERMITTED} />
  }

  if (!isAuthenticated) {
    return <Navigate to={AppRoutingConfig.APP_URL_LOGIN} />
  }

  return children
}

export default RequireAuth
