import { createSlice } from '@reduxjs/toolkit'
import { translate } from 'i18n/i18n'

const initialState = {
  communityHome: {
    loading: false,
    data: [
      {
        id: 1,
        title: translate('Home.TelexpertiseRequestsReceived'),
        count: 0
      },
      {
        id: 2,
        title: translate('Home.SecondOpinionRequestsReceived'),
        count: 0
      },
      {
        id: 3,
        title: translate('Home.AllMembers'),
        count: 0
      }
    ]
  }
}
export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: () => {}
})

export default homeSlice.reducer
