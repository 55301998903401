import { createSlice } from '@reduxjs/toolkit'
import {
  create_community_step_one,
  create_community_step_two,
  create_community_step_three,
  create_community,
  get_editor_list,
  get_author_list,
  get_community_list,
  get_community_info,
  update_community_info,
  edit_community_role,
  update_community_plan,
  deactivate_community
} from '../services/communityService'

const initialState = {
  createCommunity: {
    stepOne: null,
    stepTwo: null,
    stepThree: null
  },
  createCommunityStep: {
    loading: false
  },
  getEditorList: {
    loading: false,
    editorData: []
  },
  getAuthorList: {
    loading: false,
    authorData: []
  },
  getCommunityList: {
    getCommunity: null,
    loading: false
  },
  getCommunityInfo: {
    data: null,
    loading: false
  },
  updateCommunityInfo: {
    loading: false
  },
  editCommunityRole: {
    loading: false
  },
  updateCommunityPlan: {
    loading: false
  },
  deactivateCommunity: {
    loading: false
  },
  communityStep: 0,
  communityId: null,
  editCommunityId: undefined
}
export const communitySlice = createSlice({
  name: 'community',
  initialState,
  reducers: {
    createCommunityFormData: (state, action) => {
      state['createCommunity'][action.payload.stepFormKey] = action.payload.stepFormData
    },
    clearCommunityFormData: (state) => {
      state.createCommunity = {
        stepOne: null,
        stepTwo: null,
        stepThree: null,
        stepFour: null
      }
    },
    navigateToNextForm: (state, action) => {
      state.communityStep = action.payload
    },
    addEditCommunityId: (state, action) => {
      state.editCommunityId = action.payload
    },
    clearEditorData: (state) => {
      state.getEditorList.editorData = []
    },
    clearCommunityId: (state) => {
      state.communityId = null
    },
    clearEditCommunityId: (state) => {
      state.editCommunityId = undefined
    }
  },
  extraReducers: (builder) => {
    // Create Community Step One
    builder.addCase(create_community_step_one.pending, (state) => {
      state.createCommunityStep.loading = true
    })
    builder.addCase(create_community_step_one.fulfilled, (state) => {
      state.createCommunityStep.loading = false
    })
    builder.addCase(create_community_step_one.rejected, (state) => {
      state.createCommunityStep.loading = false
    })

    // Create Community Step Two
    builder.addCase(create_community_step_two.pending, (state) => {
      state.createCommunityStep.loading = true
    })
    builder.addCase(create_community_step_two.fulfilled, (state) => {
      state.createCommunityStep.loading = false
    })
    builder.addCase(create_community_step_two.rejected, (state) => {
      state.createCommunityStep.loading = false
    })

    // Create Community Step Three
    builder.addCase(create_community_step_three.pending, (state) => {
      state.createCommunityStep.loading = true
    })
    builder.addCase(create_community_step_three.fulfilled, (state) => {
      state.createCommunityStep.loading = false
    })
    builder.addCase(create_community_step_three.rejected, (state) => {
      state.createCommunityStep.loading = false
    })

    // Create Community
    builder.addCase(create_community.pending, (state) => {
      state.createCommunityStep.loading = true
    })
    builder.addCase(create_community.fulfilled, (state, action) => {
      state.communityId = action.payload?.response?.community_id
      state.createCommunityStep.loading = false
    })
    builder.addCase(create_community.rejected, (state) => {
      state.createCommunityStep.loading = false
    })

    // Get Editor Data
    builder.addCase(get_editor_list.pending, (state) => {
      state.getEditorList.loading = true
    })
    builder.addCase(get_editor_list.fulfilled, (state, action) => {
      const updatedEditorData = action.payload?.response?.data?.map((item) => {
        return {
          label: `${item?.first_name} ${item?.last_name}`,
          value: `${item?.id}`
        }
      })
      state.getEditorList.editorData = updatedEditorData
      state.getEditorList.loading = false
    })
    builder.addCase(get_editor_list.rejected, (state) => {
      state.getEditorList.loading = false
    })

    // Get Author Data
    builder.addCase(get_author_list.pending, (state) => {
      state.getAuthorList.loading = true
    })
    builder.addCase(get_author_list.fulfilled, (state, action) => {
      const updatedAuthorData = action.payload?.response?.data?.map((item) => {
        return {
          label: `${item?.first_name} ${item?.last_name}`,
          value: `${item?.id}`
        }
      })
      state.getAuthorList.authorData = updatedAuthorData
      state.getAuthorList.loading = false
    })
    builder.addCase(get_author_list.rejected, (state) => {
      state.getAuthorList.loading = false
    })

    // Get Community List
    builder.addCase(get_community_list.pending, (state) => {
      state.getCommunityList.loading = true
    })
    builder.addCase(get_community_list.fulfilled, (state, action) => {
      state.getCommunityList.getCommunity = action.payload?.response
      state.getCommunityList.loading = false
    })
    builder.addCase(get_community_list.rejected, (state) => {
      state.getCommunityList.loading = false
    })

    // Get Community Info
    builder.addCase(get_community_info.pending, (state) => {
      state.getCommunityInfo.loading = true
    })
    builder.addCase(get_community_info.fulfilled, (state, action) => {
      state.getCommunityInfo.data = action.payload?.response
      state.getCommunityInfo.loading = false
    })
    builder.addCase(get_community_info.rejected, (state) => {
      state.getCommunityInfo.loading = false
    })

    // Update Community Info
    builder.addCase(update_community_info.pending, (state) => {
      state.updateCommunityInfo.loading = true
    })
    builder.addCase(update_community_info.fulfilled, (state) => {
      state.updateCommunityInfo.loading = false
    })
    builder.addCase(update_community_info.rejected, (state) => {
      state.updateCommunityInfo.loading = false
    })

    // Edit Community Role
    builder.addCase(edit_community_role.pending, (state) => {
      state.editCommunityRole.loading = true
    })
    builder.addCase(edit_community_role.fulfilled, (state) => {
      state.editCommunityRole.loading = false
    })
    builder.addCase(edit_community_role.rejected, (state) => {
      state.editCommunityRole.loading = false
    })

    // Update Community Plan
    builder.addCase(update_community_plan.pending, (state) => {
      state.updateCommunityPlan.loading = true
    })
    builder.addCase(update_community_plan.fulfilled, (state) => {
      state.updateCommunityPlan.loading = false
    })
    builder.addCase(update_community_plan.rejected, (state) => {
      state.updateCommunityPlan.loading = false
    })

    // Deactivate Community
    builder.addCase(deactivate_community.pending, (state) => {
      state.deactivateCommunity.loading = true
    })
    builder.addCase(deactivate_community.fulfilled, (state) => {
      state.deactivateCommunity.loading = false
    })
    builder.addCase(deactivate_community.rejected, (state) => {
      state.deactivateCommunity.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const {
  createCommunityFormData,
  clearCommunityFormData,
  navigateToNextForm,
  addEditCommunityId,
  clearEditorData,
  clearCommunityId,
  clearEditCommunityId
} = communitySlice.actions

export default communitySlice.reducer
