import { Button, Dropdown, Flex } from 'antd'
import './SelectableDropdownButton.scss'

const SelectableDropdownButton = ({
  menuItems,
  suffixIcon,
  prefixIcon,
  value,
  setValue,
  buttonProps,
  dropDownProps,
  customClickHandler,
  selectable = true
}) => {
  /* function definition to handle menu clicked */
  const handleMenuClicked = (event) => {
    const clickedItem = menuItems?.find((item) => item.key === event.key)
    setValue && setValue(clickedItem.label)
    customClickHandler && customClickHandler(clickedItem)
  }
  /* function definition to handle menu clicked */

  return (
    <Dropdown
      menu={{
        items: menuItems,
        selectable: selectable,
        onClick: handleMenuClicked
      }}
      placement="bottom"
      trigger={['click']}
      {...dropDownProps}
    >
      <Button type="text" className="home-filter-button" {...buttonProps}>
        <Flex align="center" gap="0.5rem">
          {suffixIcon}
          {value}
          {prefixIcon}
        </Flex>
      </Button>
    </Dropdown>
  )
}

export default SelectableDropdownButton
