import { Select } from 'antd'
import './Pagination.scss'

const PaginationListing = ({ pageSize, setPageSize, onPageChange, numberPagesArray }) => {
  return (
    <div className="pagination-listing-container">
      <Select
        value={pageSize}
        onChange={(value) => {
          setPageSize(Number(value))
          onPageChange(1)
        }}
        options={numberPagesArray.map((element) => {
          return { value: element, label: `${element} / page` }
        })}
      />
    </div>
  )
}
/* Component for total limiting pages */

/* Function definition to get the required number of pages */
const getRequiredPages = ({ pagesCount, requiredPagesCount, pageNo }) => {
  const ceiling = Math.ceil(requiredPagesCount / 2)
  const floor = Math.floor(requiredPagesCount / 2)

  if (pagesCount < requiredPagesCount) {
    return {
      start: 1,
      end: pagesCount + 1
    }
  } else if (pageNo >= 1 && pageNo <= ceiling) {
    return { start: 1, end: requiredPagesCount + 1 }
  } else if (pageNo + floor >= pagesCount) {
    return { start: pagesCount - requiredPagesCount + 1, end: pagesCount + 1 }
  } else {
    return { start: pageNo - ceiling + 1, end: pageNo + floor + 1 }
  }
}
/* Function definition to get the required number of pages */

/* Component for individual pagination item */
const PaginationItem = ({ page, currentPage, onPageChange, isDisabled }) => {
  return (
    <li
      className={`pagination-item ${
        page === currentPage ? 'active-page' : ''
      } ${isDisabled ? 'button-disabled' : ''}`}
      onClick={() => onPageChange(page)}
    >
      <span className="pagination-link">{page}</span>
    </li>
  )
}
/* Component for individual pagination item */

/* Main pagination component */
const Pagination = ({
  pageNo,
  total,
  pageSize,
  setPageSize,
  onPageChange,
  customPageSizes = []
}) => {
  const pagesCount = Math.ceil(total / pageSize)

  const range = (start, end) => {
    const array = Array.from({ length: end - start }, (_, index) => index + start)
    return array
  }
  const requiredPages = getRequiredPages({
    pagesCount,
    requiredPagesCount: 4,
    pageNo
  })
  const totalPages = range(requiredPages.start, requiredPages.end)
  const isFirstPage = pageNo === 1
  const isLastPage = pageNo === pagesCount
  const numberPagesArray = [10, 15, 20, ...customPageSizes]

  return (
    <div className="pagination-container">
      <ul className="pagination">
        <PaginationItem
          page={<span className="next-prev-symbol">&laquo;</span>}
          currentPage={pageNo}
          onPageChange={() => onPageChange(1)}
          isDisabled={isFirstPage}
        />
        <PaginationItem
          page={<span>Previous</span>}
          currentPage={pageNo}
          onPageChange={() => onPageChange(pageNo - 1)}
          isDisabled={isFirstPage}
        />
        {totalPages.map((page) => {
          return (
            <PaginationItem
              page={page}
              key={page}
              currentPage={pageNo}
              onPageChange={onPageChange}
            />
          )
        })}
        <PaginationItem
          page={<span>Next</span>}
          currentPage={pageNo}
          onPageChange={() => onPageChange(pageNo + 1)}
          isDisabled={isLastPage}
        />
        <PaginationItem
          page={<span className="next-prev-symbol"> &raquo;</span>}
          currentPage={pageNo}
          onPageChange={() => onPageChange(pagesCount)}
          isDisabled={isLastPage}
        />
      </ul>

      <PaginationListing
        pageSize={pageSize}
        setPageSize={setPageSize}
        onPageChange={onPageChange}
        numberPagesArray={numberPagesArray}
      />
    </div>
  )
}

export default Pagination
/* Main pagination component */
