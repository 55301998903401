import { decryptData } from 'assets/config/AppEncryptDecryptConfig'
import { createContext, useContext } from 'react'

export const UserDetailsContext = createContext()

const UserDetailsProvider = ({ children }) => {
  const decryptedUserDetails = decryptData('user')
  const decryptedSelectedProfileDetails = decryptData('selectedProfile')
  const userRole =
    decryptedUserDetails?.auth_user_role?.sub_role ||
    decryptedUserDetails?.auth_user_role?.role ||
    ''

  const isAuthenticated =
    decryptedUserDetails && decryptedUserDetails !== null
      ? decryptedUserDetails.isAuthenticated
      : false

  const value = {
    selectedRole: userRole,
    userDetails: decryptedUserDetails,
    selectedProfileDetails: decryptedSelectedProfileDetails,
    isAuthenticated
  }

  console.log(decryptedUserDetails, 'decryptedUserDetails')

  return <UserDetailsContext.Provider value={value}>{children}</UserDetailsContext.Provider>
}

// eslint-disable-next-line react-refresh/only-export-components
export const useUserDetails = () => {
  return useContext(UserDetailsContext)
}

export default UserDetailsProvider
