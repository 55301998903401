import { createSlice } from '@reduxjs/toolkit'
import {
  get_policy_list,
  update_policy,
  update_faq,
  get_faq_list
} from '../services/configurePoliciesService'

export const configurePoliciesSlice = createSlice({
  name: 'configurePolicies',
  initialState: {
    getPolicy: {
      loading: false,
      data: null
    },
    updatePolicy: {
      loading: false
    },
    updateFaq: {
      loading: false
    },
    getFaqList: {
      loading: false,
      getFaq: [],
      pagination: {}
    }
  },
  reducers: {
    updatePrivacyPolicy: (state, action) => {
      state.getPolicy.data = action.payload
    }
  },
  extraReducers: (builder) => {
    // Get policy List
    builder.addCase(get_policy_list.pending, (state) => {
      state.getPolicy.loading = true
    })
    builder.addCase(get_policy_list.fulfilled, (state, action) => {
      state.getPolicy.data = action.payload?.response
      state.getPolicy.loading = false
    })
    builder.addCase(get_policy_list.rejected, (state) => {
      state.getPolicy.loading = false
    })

    // Update policy
    builder.addCase(update_policy.pending, (state) => {
      state.updatePolicy.loading = true
    })
    builder.addCase(update_policy.fulfilled, (state) => {
      state.updatePolicy.loading = false
    })
    builder.addCase(update_policy.rejected, (state) => {
      state.updatePolicy.loading = false
    })

    // Update Faq
    builder.addCase(update_faq.pending, (state) => {
      state.updateFaq.loading = true
    })
    builder.addCase(update_faq.fulfilled, (state) => {
      state.updateFaq.loading = false
    })
    builder.addCase(update_faq.rejected, (state) => {
      state.updateFaq.loading = false
    })

    // Get Faq List
    builder.addCase(get_faq_list.pending, (state) => {
      state.getFaqList.loading = true
    })
    builder.addCase(get_faq_list.fulfilled, (state, action) => {
      state.getFaqList.getFaq = action.payload?.response?.data
      state.getFaqList.pagination = action.payload?.response?.pagination
      state.getFaqList.loading = false
    })
    builder.addCase(get_faq_list.rejected, (state) => {
      state.getFaqList.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const { updatePrivacyPolicy } = configurePoliciesSlice.actions

export default configurePoliciesSlice.reducer
