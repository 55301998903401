import { createSlice } from '@reduxjs/toolkit'
import {
  community_create_token,
  community_revoke_token,
  get_community,
  upload_profile,
  get_user_activity
} from '../services/commonService'
import { decryptData, encryptData } from 'assets/config/AppEncryptDecryptConfig'
import { AppPermissionsConfig } from 'assets/config/AppPermissionsConfig'
import { roles } from '../../constants'

const initialState = {
  getCommunity: {
    loading: false,
    data: null
  },
  communityCreateToken: {
    loading: false
  },
  communityRevokeToken: {
    loading: false
  },
  uploadProfile: {
    loading: false
  },
  userActivity: {
    loading: false,
    data: []
  }
}
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    clearCommonDataDetails: () => initialState
  },
  extraReducers: (builder) => {
    // Get community
    builder.addCase(get_community.pending, (state) => {
      state.getCommunity.loading = true
    })
    builder.addCase(get_community.fulfilled, (state, action) => {
      state.getCommunity.loading = false
      const userDetails = action?.payload?.userDetails || {}
      const responseArray = action?.payload?.response || []

      state.getCommunity.data = [
        userDetails,
        ...responseArray
          .map((element) => {
            return {
              ...element,
              label: element?.community_title,
              value: element?.id
            }
          })
          .filter((_element) => {
            return _element.community_members[0].role !== 'member'
          })
      ]
    })
    builder.addCase(get_community.rejected, (state) => {
      state.getCommunity.loading = false
    })

    // Community create token
    builder.addCase(community_create_token.pending, (state) => {
      state.communityCreateToken.loading = true
    })
    builder.addCase(community_create_token.fulfilled, (state, action) => {
      state.communityCreateToken.loading = false
      const response = action?.payload?.response
      const user_data = decryptData('user')

      const permissions = AppPermissionsConfig.getAdminPermissionsObjFromArr(
        user_data,
        response?.role
      )

      /* setting package permissions */
      user_data.activityDetail.community_package = response?.getActivity
      /* setting package permissions */

      /* change the role on change */
      user_data.auth_user_role.sub_role = response?.role
      /* change the role on change */

      /* change permissions on change */
      user_data.permissions = permissions
      /* change permissions on change */

      encryptData('user', user_data)
      window.location.reload()
    })
    builder.addCase(community_create_token.rejected, (state) => {
      state.communityCreateToken.loading = false
    })

    // Community revoke token
    builder.addCase(community_revoke_token.pending, (state) => {
      state.communityRevokeToken.loading = true
    })
    builder.addCase(community_revoke_token.fulfilled, (state) => {
      state.communityRevokeToken.loading = false
      const user_data = decryptData('user')

      /* remove community package details */
      if (user_data.activityDetail.community_package)
        delete user_data.activityDetail.community_package
      /* remove community package details */

      /* remove sub_role */
      if (user_data.auth_user_role.sub_role) delete user_data.auth_user_role.sub_role
      /* remove sub_role */
      const permissions = AppPermissionsConfig.getAdminPermissionsObjFromArr(
        user_data,
        roles.physician
      )

      /* setting user permissions again on change */
      user_data.permissions = permissions
      /* setting user permissions again on change */

      encryptData('user', user_data)
      window.location.reload()
    })
    builder.addCase(community_revoke_token.rejected, (state) => {
      state.communityRevokeToken.loading = false
    })

    // get Specialities
    builder.addCase(upload_profile.pending, (state) => {
      state.uploadProfile.loading = true
    })
    builder.addCase(upload_profile.fulfilled, (state) => {
      state.uploadProfile.loading = false
    })
    builder.addCase(upload_profile.rejected, (state) => {
      state.uploadProfile.loading = false
    })

    // get Specialities
    builder.addCase(get_user_activity.pending, (state) => {
      state.userActivity.loading = true
    })
    builder.addCase(get_user_activity.fulfilled, (state, action) => {
      state.userActivity.loading = false
      state.userActivity.data = action.payload?.response
    })
    builder.addCase(get_user_activity.rejected, (state) => {
      state.userActivity.loading = false
    })
  }
})

export const { clearCommonDataDetails } = commonSlice.actions

export default commonSlice.reducer
