import { translate } from 'i18n/i18n'
import NotificationList from './NotificationList'
import { Badge, Flex } from 'antd'
import './NotificationWidget.scss'

export const notificationsTabsArr = (notifictionsType, getNotificationsList) => {
  return [
    {
      key: 'All',
      label: (
        <Flex align="center" justify="center" gap={'0.2rem'}>
          <p>{translate('Notifications.All')}</p>
          {notifictionsType === 'All' && (
            <Badge count={getNotificationsList?.notificationListData?.unreadCount} />
          )}
        </Flex>
      ),
      children: (
        <NotificationList
          notifictionsType={notifictionsType}
          listData={getNotificationsList?.notificationListData?.data}
          loading={getNotificationsList?.loading}
        />
      )
    },
    {
      key: 'TE',
      label: (
        <Flex align="center" justify="center" gap={'0.2rem'}>
          <p>{translate('Notifications.Telexpertise')}</p>
          {notifictionsType === 'TE' && (
            <Badge count={getNotificationsList?.notificationListData?.unreadCount} />
          )}
        </Flex>
      ),
      children: (
        <NotificationList
          notifictionsType={notifictionsType}
          listData={getNotificationsList?.notificationListData?.data}
          loading={getNotificationsList?.loading}
        />
      )
    },
    {
      key: 'SO',
      label: (
        <Flex align="center" justify="center" gap={'0.2rem'}>
          <p>{translate('Notifications.SecondOpinion')}</p>
          {notifictionsType === 'SO' && (
            <Badge count={getNotificationsList?.notificationListData?.unreadCount} />
          )}
        </Flex>
      ),
      children: (
        <NotificationList
          notifictionsType={notifictionsType}
          listData={getNotificationsList?.notificationListData?.data}
          loading={getNotificationsList?.loading}
        />
      )
    }
  ]
}
