import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Typography } from 'antd'
import './BreadCrumbs.scss'

const BreadCrumbs = ({ isPrimaryLayout }) => {
  /* location dependencies */
  const location = useLocation()
  /* location dependencies */

  const [breadCrumbsArr, setBreadCrumbsArr] = useState([])

  /* initial dependencies */
  useEffect(() => {
    const tempPathName = location.pathname.split('/').filter(Boolean)
    tempPathName.length = 2 // REMOVE THIS IF YOU WANT NESTED BREADCRUMBS

    const getCapitalizedStringArr = (element) => {
      return element.split('-').map((e) => e.substring(0, 1).toUpperCase() + e.slice(1))
    }

    const getFinalStringArrWithAndReplaced = (element) => {
      let tempCapitalizedStringArr = getCapitalizedStringArr(element)
      const indexOfAnd = tempCapitalizedStringArr.indexOf('And')
      if (indexOfAnd !== -1) tempCapitalizedStringArr[indexOfAnd] = '&'
      return tempCapitalizedStringArr
    }

    const tempCapitalizedNames = tempPathName.map((element) =>
      getFinalStringArrWithAndReplaced(element).join(' ')
    )

    setBreadCrumbsArr(tempCapitalizedNames)
  }, [location.pathname])
  /* initial dependencies */

  /* function definition to get color based on layout */
  const getColorBasedOnLayout = () => {
    return isPrimaryLayout ? 'var(--pure-white)' : 'var(--primary-color)'
  }
  /* function definition to get color based on layout */

  const BreadCrumbTitle = ({ children }) => {
    return (
      <Typography.Title level={2} style={{ margin: 0, color: getColorBasedOnLayout() }}>
        {children}
      </Typography.Title>
    )
  }

  const BreadCrumbSubTitle = ({ children }) => {
    return (
      <Typography.Title level={4} style={{ margin: 0, color: getColorBasedOnLayout() }}>
        {children}
      </Typography.Title>
    )
  }

  return (
    <div className="breadcrumb-container">
      {breadCrumbsArr?.map((element, index) => {
        if (index === 0) return <BreadCrumbTitle key={element}>{element}</BreadCrumbTitle>
        return <BreadCrumbSubTitle key={element}>{element}</BreadCrumbSubTitle>
      })}
    </div>
  )
}

export default BreadCrumbs
