import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get account settings
export const get_account_settings = createAsyncThunk(
  'accountSettings/get_account_settings',
  async ({ showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/get/account-settings`)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update account settings
export const update_account_settings = createAsyncThunk(
  'accountSettings/update_account_settings',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/update/account-settings`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
