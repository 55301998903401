import { createAsyncThunk } from '@reduxjs/toolkit'
// import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import axiosInstance from 'axiosInstance'
import { translate } from 'i18n/i18n'

// get professional info
export const get_professional_info = createAsyncThunk(
  'professionalInfo/get_professional_info',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/get/professional-information`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update professional info
export const update_professional_info = createAsyncThunk(
  'professionalInfo/update_professional_info',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        `v1/profile/update/professional-information`,
        payload
      )
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.msg.length > 0
              ? response?.data?.[0]?.msg
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
