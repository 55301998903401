import { Card, Flex, Typography } from 'antd'
import { useEffect, useState } from 'react'
import { ExclamationCircleFilled } from '@ant-design/icons'

const NetworkErrorDetection = () => {
  const [isOffline, setIsOffline] = useState(!window.navigator.onLine)

  useEffect(() => {
    // Handler to update offline status
    const handleOffline = () => setIsOffline(true)
    const handleOnline = () => setIsOffline(false)

    // Register event listeners
    window.addEventListener('offline', handleOffline)
    window.addEventListener('online', handleOnline)

    // Cleanup on unmount
    return () => {
      window.removeEventListener('offline', handleOffline)
      window.removeEventListener('online', handleOnline)
    }
  }, [])
  return (
    <>
      {isOffline && (
        <Card size="small" style={{ backgroundColor: 'var(--pale-pink)', margin: '10px 20px' }}>
          <Flex justify="space-between" align="center" wrap="wrap" gap="1rem">
            <Flex align="center" gap={'0.5rem'}>
              <ExclamationCircleFilled
                style={{ fontSize: '1.5rem', color: 'var(--light-coral)' }}
              />
              <Typography.Title level={4} style={{ margin: 0, color: 'var(--fire-brick)' }}>
                Connection lost. Attempting to reconnect...
              </Typography.Title>
            </Flex>
            {/* <Button size="large" className="admin-dashboard-btn">
              <Flex align="center" gap={'0.5rem'}>
                {t('Dashboard.ManageLDAPConnexion')} <RightArrowIcon color="var(--pure-white)" />
              </Flex>
            </Button> */}
          </Flex>
        </Card>
      )}
    </>
  )
}

export default NetworkErrorDetection
