import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import { useUserDetails } from 'context/UserDetailsProvider'
import { Navigate } from 'react-router-dom'

const RoleBasedComponent = ({ componentMap }) => {
  const { selectedRole } = useUserDetails()

  const Component = componentMap[selectedRole]

  return Component ? Component : <Navigate to={AppRoutingConfig.APP_URL_NOT_PERMITTED} />
}

export default RoleBasedComponent
