import { useEffect } from 'react'

const useClickOutside = (ref, onClickOutside) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref?.current && !ref?.current?.contains(event.target)) {
        onClickOutside()
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document?.removeEventListener('click', handleClickOutside)
    }
  }, [onClickOutside, ref])
}

export default useClickOutside
