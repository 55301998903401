import { createSlice } from '@reduxjs/toolkit'
import {
  get_instructions_list,
  get_instructions,
  update_instructions
} from '../services/instructionsService'

export const instructionsSlice = createSlice({
  name: 'instructions',
  initialState: {
    getInstructionsList: {
      loading: false,
      getInstructionsData: [],
      pagination: {}
    },
    getInstructions: {
      loading: false,
      instructionsData: []
    },
    updateInstructions: {
      loading: false
    }
  },
  reducers: {
    clearInstructions: (state) => {
      state.getInstructions.instructionsData = []
    }
  },
  extraReducers: (builder) => {
    // Get Instructions List
    builder.addCase(get_instructions_list.pending, (state) => {
      state.getInstructionsList.loading = true
    })
    builder.addCase(get_instructions_list.fulfilled, (state, action) => {
      state.getInstructionsList.getInstructionsData = action.payload?.response?.data
      state.getInstructionsList.pagination = action.payload?.response?.pagination
      state.getInstructionsList.loading = false
    })
    builder.addCase(get_instructions_list.rejected, (state) => {
      state.getInstructionsList.loading = false
    })

    // Get Instructions
    builder.addCase(get_instructions.pending, (state) => {
      state.getInstructions.loading = true
    })
    builder.addCase(get_instructions.fulfilled, (state, action) => {
      state.getInstructions.instructionsData = action.payload?.response
      state.getInstructions.loading = false
    })
    builder.addCase(get_instructions.rejected, (state) => {
      state.getInstructions.loading = false
    })

    // Update Instructions
    builder.addCase(update_instructions.pending, (state) => {
      state.updateInstructions.loading = true
    })
    builder.addCase(update_instructions.fulfilled, (state) => {
      state.updateInstructions.loading = false
    })
    builder.addCase(update_instructions.rejected, (state) => {
      state.updateInstructions.loading = false
    })
  }
})

export const { clearInstructions } = instructionsSlice.actions
export default instructionsSlice.reducer
