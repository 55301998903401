// Created By - @Shail-Shah-Arche
// Created At - 18/04/2024
/*
This file is intended for creating functions that provide form utility 
functionality, such as validation rules and formatting helpers.
*/

import dayjs from 'dayjs'
import { translate } from '../i18n/i18n'

/* Validates an input field to ensure it is not empty and does not start with whitespace */
export const inputFieldValidation = (inputFieldValidationText) => {
  return [
    {
      required: true,
      message: `${translate(`CommonUtils.Validations.PleaseEnterYour`)} ${inputFieldValidationText}`
    },
    {
      whitespace: true,
      message: `${translate(`CommonUtils.Validations.PleaseEnterYour`)} ${inputFieldValidationText}`
    },
    {
      validator: (_, value) => {
        if (value && value.length > 50)
          return Promise.reject(
            `${translate('CommonUtils.Validations.ExcededMaxCharactersForInputField')}`
          )
        return Promise.resolve()
      }
    }
  ]
}
/* Validates an input field to ensure it is not empty and does not start with whitespace */

/* Validates an textarea to ensure it is not empty and does not start with whitespace */
export const textareaFieldValidation = (inputFieldValidationText) => {
  return [
    {
      required: true,
      message: `${translate(`CommonUtils.Validations.PleaseEnterYour`)} ${inputFieldValidationText}`
    },
    {
      whitespace: true,
      message: `${translate(`CommonUtils.Validations.PleaseEnterYour`)} ${inputFieldValidationText}`
    },
    {
      validator: (_, value) => {
        if (value && value.length > 250)
          return Promise.reject(
            `${translate('CommonUtils.Validations.ExcededMaxCharactersForTextAreaField')}`
          )
        return Promise.resolve()
      }
    }
  ]
}
/* Validates an textarea to ensure it is not empty and does not start with whitespace */

/* Validates an input field to ensure it is not empty and does not start with whitespace */
export const emailFieldValidation = (inputFieldValidationText) => {
  return [
    ...inputFieldValidation(inputFieldValidationText),
    {
      type: 'email',
      message: translate('CommonUtils.Validations.PleaseEnterAValidEmail')
    }
  ]
}
/* Validates an input field to ensure it is not empty and does not start with whitespace */

/* Validates an input number field to ensure it is not empty and not less than zero */
export const inputNumberFieldValidation = (inputNumberFieldValidationText) => {
  return [
    {
      required: true,
      message: `${translate(
        `CommonUtils.Validations.PleaseEnterYour`
      )} ${inputNumberFieldValidationText}`
    },
    {
      validator: (_, value) => {
        if (value && value < 1)
          return Promise.reject(
            `${inputNumberFieldValidationText} ${translate(`CommonUtils.Validations.ShouldNotBeLessThanZero`)}`
          )
        return Promise.resolve()
      }
    }
  ]
}
/* Validates an input number field to ensure it is not empty and not less than zero */

/* Validates an otp input number field to ensure it is not empty and not less than zero */
export const otpInputFieldValidation = (inputNumberFieldValidationText, otpLength = 6) => {
  return [
    {
      required: true,
      message: `${translate(
        `CommonUtils.Validations.PleaseEnterYour`
      )} ${inputNumberFieldValidationText}`
    },
    {
      validator: (_, value) => {
        if (value && value.length < otpLength)
          return Promise.reject(
            `${translate(`CommonUtils.Validations.PleaseEnterYour`)} ${inputNumberFieldValidationText}`
          )
        return Promise.resolve()
      }
    }
  ]
}
/* Validates an otp input number field to ensure it is not empty and not less than zero */

/* Validates a phone input number field to ensure it is not empty and not less than zero */
export const phoneInputFieldValidation = (inputNumberFieldValidationText) => {
  return [
    {
      required: true,
      validator: async (_, value) => {
        if (value && !value.phone)
          return Promise.reject(
            `${translate(`CommonUtils.Validations.PleaseEnterYour`)} ${inputNumberFieldValidationText}`
          )
        return Promise.resolve()
      }
    }
  ]
}
/* Validates a phone input number field to ensure it is not empty and not less than zero */

/* Validates an input field to ensure it is not empty and does not start with whitespace */
export const percentageLimitFieldValidation = (inputFieldValidationText, percentageLimit = 100) => {
  return [
    {
      required: true,
      message: `${translate(`CommonUtils.Validations.PleaseEnterYour`)} ${inputFieldValidationText}`
    },
    {
      validator: (_, value) => {
        if (value && value > percentageLimit) {
          return Promise.reject(
            `${inputFieldValidationText} ${translate(`CommonUtils.Validations.ShouldNotBeGreaterThanHundred`)}`
          )
        } else if (value && value < 1) {
          return Promise.reject(
            `${inputFieldValidationText} ${translate('CommonUtils.Validations.ShouldBeGreaterThanZero')}`
          )
        }
        return Promise.resolve()
      }
    }
  ]
}
/* Validates an input field to ensure it is not empty and does not start with whitespace */

/* Function definition for custom filter in antd-country-phone-input */
export const getPhoneInputFilterOptions = (input, option) => {
  const key = option?.key.toString().toLowerCase()
  const inputText = input.toLowerCase().trim()
  const lastSpaceIndex = key.lastIndexOf(' ')

  const name = key.substring(0, lastSpaceIndex)
  const code = key.substring(lastSpaceIndex + 1)
  return name.startsWith(inputText) || code.startsWith(inputText)
}
/* Function definition for custom filter in antd-country-phone-input */

/* Function definition to check if password meets the requirements using regex */
export const checkIfStringIncludesUpperAndLowerCase = (value) => {
  const upperCaseLetter = /[A-Z]/.test(value)
  const lowerCaseLetter = /[a-z]/.test(value)

  return upperCaseLetter && lowerCaseLetter
}

export const checkIfStringIncludesAtleastOneNumber = (value) => {
  const checkForOneNumber = /[0-9]/.test(value)
  return checkForOneNumber
}

export const checkIfStringIncludesAtleastOneSpecialCharacter = (value) => {
  const checkForOneSpecialCharacter = /[^A-Za-z0-9]/.test(value)
  return checkForOneSpecialCharacter
}
/* Function definition to check if password meets the requirements using regex */

/*Filters dropdown options based on the input value*/
export const dropdownFilterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
/*Filters dropdown options based on the input value*/

/* Validates an select field to ensure it is not empty */
export const selectFieldValidation = (inputFieldValidationText) => {
  return [
    {
      required: true,
      message: `${translate(`CommonUtils.Validations.PleaseSelectYour`)} ${inputFieldValidationText}`
    }
  ]
}
/* Validates an select field to ensure it is not empty */

/* function definition to capitalize the first letter of a string */
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
/* function definition to capitalize the first letter of a string */

/* function definition to get the pill badge color based on priority */
export const getPillBadgeColorBasedOnPriority = (text) => {
  switch (text) {
    case 'Low':
      return 'var(--cerulean-blue)'

    case 'Medium':
      return 'var(--sandy-brown)'

    case 'High':
      return 'var(--postal-red)'

    default:
      break
  }
}
/* function definition to get the pill badge color based on priority */

/* function definition to get the pill background color and text color based on status */
export const getPillBackgroundColorAndColorBasedOnStatus = (text) => {
  switch (text) {
    case 'Draft':
      return {
        background: 'var(--cerulean-blue)',
        color: 'var(--pure-white)'
      }
    case 'Approve':
      return {
        background: 'var(--baby-blue)',
        color: 'var(--primary-color)'
      }

    case 'Pending':
      return {
        background: 'var(--alice-blue)',
        color: 'var(--black-pearl)'
      }

    case 'Decline':
      return {
        background: 'var(--piggy-pink)',
        color: 'var(--pinkish-red)'
      }

    case 'Completed':
      return {
        background: 'var(--mint-cream)',
        color: 'var(--forest-green)'
      }

    case 'In_Progress':
      return {
        background: 'var(--old-lace)',
        color: 'var(--golden-brown)'
      }

    case 'Reject':
      return {
        background: 'var(--blush-pink)',
        color: 'var(--fire-brick)'
      }

    case 'Unassigned':
      return {
        background: 'var(--old-lace)',
        color: 'var(--golden-brown)'
      }

    default:
      break
  }
}

export const getModifiedLabelFromStatus = (key) => {
  switch (key) {
    case 'Reject':
      return translate('CommonUtils.Rejected')
    case 'Approve':
      return translate('CommonUtils.Accepted')
    case 'Decline':
      return translate('CommonUtils.Declined')

    default:
      break
  }
}
/* function definition to get the pill background color and text color based on status */

/* function definition to convert the date into ISO format */
export const dateObjToISOFormat = (dateObj) => {
  let formattedDate = ''

  if (dateObj && dateObj !== null && dateObj !== undefined) {
    const tempDateObj = new Date(dateObj)
    // const localOffset = tempDateObj.getTimezoneOffset();
    // tempDateObj.setMinutes(tempDateObj.getMinutes() - localOffset);
    tempDateObj.setHours(0, 0, 0, 0)
    formattedDate = dayjs(dateObj).toISOString()
  }
  return formattedDate
}
/* function definition to convert the date into ISO format */

/* function definition to generate delete items array */
export const deletedItemsArray = (initialArray = [], updatedArray = []) => {
  return initialArray
    ?.filter((item) => !updatedArray.some((updatedItem) => updatedItem.id === item.id))
    ?.map((item) => ({ id: item?.id, is_delete: true }))
}
/* function definition to generate delete items array */

/* function for copy text */
export const handleCopy = async (text, setIsShowToolTip) => {
  try {
    await navigator.clipboard.writeText(text)
    setIsShowToolTip(true)
    setTimeout(() => setIsShowToolTip(false), 1500)
  } catch {
    setIsShowToolTip(false)
  }
}
/* function for copy text */

/* For generating file preview from file of type image */
export const readImageFileAsync = async (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsDataURL(file)
  })
}
/* For generating file preview from file of type image */

/* function definition to convert time object into 12 hour format */
export const timeObjToDispViewTimeWithSuffix = (timeObj) => {
  let formattedTime = ''

  if (timeObj && timeObj !== '' && timeObj !== null && timeObj !== undefined) {
    const date = new Date(timeObj)
    let hours = date.getHours()
    let minutes = date.getMinutes()

    let suffix = hours >= 12 ? 'pm' : 'am'

    hours = hours % 12
    hours = hours ? hours : 12
    minutes = minutes < 10 ? '0' + minutes : minutes

    formattedTime = hours + ':' + minutes + ' ' + suffix
  }
  return formattedTime
}
/* function definition to convert time object into 12 hour format */

/* function to genrate phone number object */
export const createPhoneNumberObject = (countryCode, phoneNumber) => {
  if (countryCode && phoneNumber) {
    return {
      code: countryCode,
      phone: phoneNumber?.split('+')[1].slice(countryCode.toString().length)
    }
  }
}
/* function to genrate phone number object */

/* function definition to calculate remaining time for the physician to respond */
export const getTimeRemainingToRespondFn = (repliesWithin, updatedAt) => {
  const parseRepliesWithin = (value) => {
    if (value) {
      const [number, unit] = value.split('-')
      const parsedNumber = parseInt(number, 10)

      switch (unit) {
        case 'hr':
          return parsedNumber * 60 * 60 * 1000
        case 'day':
          return parsedNumber * 24 * 60 * 60 * 1000
        case 'week':
          return parsedNumber * 7 * 24 * 60 * 60 * 1000
        default:
          return 0
      }
    }
  }

  if (updatedAt === null || updatedAt === undefined) return

  const timeInMilliseconds = parseRepliesWithin(repliesWithin)
  const tempUpdatedAt = new Date(updatedAt)
  const incrementedDate = new Date(tempUpdatedAt.getTime() + timeInMilliseconds)

  const today = new Date()
  const timeRemaining = incrementedDate - today

  const timeRemainingDisplayTextInString = (milliseconds) => {
    if (milliseconds <= 0) {
      return "Time's up"
    }
    const hours = Math.floor(milliseconds / (60 * 60 * 1000))
    const days = Math.floor(hours / 24)
    const remainingHours = hours % 24

    if (days > 0) {
      return `${days} days${remainingHours > 0 ? `, ${remainingHours} hours` : ''} left to respond`
    } else {
      return `${remainingHours} hours left to respond`
    }
  }

  return timeRemainingDisplayTextInString(timeRemaining)
}
/* function definition to calculate remaining time for the physician to respond */
