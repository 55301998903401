/* eslint-disable react-refresh/only-export-components */
import { Modal } from 'antd'
import Picker from '@emoji-mart/react'
import emojiData from '@emoji-mart/data/sets/14/apple.json'
import React from 'react'

const EmojiPickerDialog = ({ open, onClose, handleGetEmoji }) => {
  /* dialog close dependencies */
  const handleCloseDialog = () => {
    onClose()
  }
  /* dialog close dependencies */

  /* Function definition for Emoji Picker - On change */
  const handleEmojiSelect = (emoji) => {
    handleGetEmoji(emoji.native)
  }
  /* Function definition for Emoji Picker - On change */

  return (
    <Modal
      centered
      footer={false}
      open={open}
      onCancel={handleCloseDialog}
      width={300}
      closable={false}
      style={{ margin: 0, padding: 0 }}
    >
      <Picker
        set="apple"
        data={emojiData}
        onEmojiSelect={handleEmojiSelect}
        emojiButtonSize={27}
        emojiSize={20}
        previewPosition="none"
      />
    </Modal>
  )
}

export default React.memo(EmojiPickerDialog)
