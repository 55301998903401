// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, isSupported } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyD3OOjyyTC4rG3QfwfRR8R_A4MdAfuaLYQ',
  authDomain: 'synapp-expertise.firebaseapp.com',
  projectId: 'synapp-expertise',
  storageBucket: 'synapp-expertise.appspot.com',
  messagingSenderId: '289871049880',
  appId: '1:289871049880:web:c396b1b016080707eb0ced',
  measurementId: 'G-KKEQVC6XCM'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

async function getSupportFn(cb) {
  return await cb()
}

export const messaging = getSupportFn(isSupported) ? getMessaging(app) : null

export const generateToken = async () => {
  if (getSupportFn(isSupported)) {
    const permission = await Notification.requestPermission()
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey:
          'BMK1ttKB-t2q-TgTd-hEEc9Ze1tz3ECJVQOHemH5nbGa_vyvnGW9Xep7eJBbeywXpGTE6SB62Gr9bNwUwc8I1U8'
      })
      return token
    }
  }
}
