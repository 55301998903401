import RequireAuth from 'app/requireAuth'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import ErrorBoundary from 'components/common/error-boundary'
import PrimaryLayout from 'components/layouts/primary-layout'
import UserDetailsProvider from 'context/UserDetailsProvider'
import { Navigate } from 'react-router-dom'
import * as lazy from './lazyComponents'
import { roles } from '../../constants'
import CaseLayout from 'components/layouts/case-layout'
import MainLayout from 'components/layouts/main-layout'

const CommonWrapper = ({ Layout }) => {
  return (
    <UserDetailsProvider>
      <RequireAuth allowedRoles={[roles.patient]}>
        <Layout />
      </RequireAuth>
    </UserDetailsProvider>
  )
}

export const patient_routes = [
  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: <CommonWrapper Layout={CaseLayout} />,
    children: [
      {
        path: AppRoutingConfig.CREATE_SECOND_OPINION_CASE,
        element: <lazy.CreateSecondOpinionCase />
      }
    ]
  },

  {
    path: AppRoutingConfig.APP_URL_ROOT,
    element: <CommonWrapper Layout={MainLayout} />,
    children: [
      {
        path: AppRoutingConfig.APPOINTMENTS,
        element: <lazy.Appointments />
      }
    ]
  },

  {
    path: AppRoutingConfig.VIEW_SO_CASE_REQUEST,
    element: (
      <UserDetailsProvider>
        <CaseLayout />
      </UserDetailsProvider>
    ),
    children: [
      {
        path: AppRoutingConfig.VIEW_SO_CASE_REQUEST_BY_ID,
        element: <lazy.ViewSOCase />
      }
    ]
  },

  {
    path: AppRoutingConfig.SETTINGS,
    element: <CommonWrapper Layout={PrimaryLayout} />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Navigate to={AppRoutingConfig.PROFILE_INFORMATION} replace />
      },
      {
        path: AppRoutingConfig.PAYMENT_INFORMATION,
        element: <lazy.PaymentInformation />
      }
    ]
  }
]
