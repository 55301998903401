import { useEffect } from 'react'

const TawkToWidget = ({ visitorName, visitorEmail, visitorHash }) => {
  useEffect(() => {
    // Load Tawk.to widget
    const tawkScript = document.createElement('script')
    tawkScript.async = true
    tawkScript.src = 'https://embed.tawk.to/66a186dc32dca6db2cb530bf/1i3jfdj70' // Replace with your actual Tawk.to property ID
    tawkScript.charset = 'UTF-8'
    tawkScript.setAttribute('crossorigin', '*')

    // Set up global Tawk_API object
    window.Tawk_API = window.Tawk_API || {}
    window.Tawk_LoadStart = new Date()

    // Append the script to the body
    document.body.appendChild(tawkScript)

    // Tawk_API onLoad function to set visitor attributes after the widget loads
    tawkScript.onload = () => {
      if (window.Tawk_API) {
        window.Tawk_API.onLoad = function () {
          // This will only work if the email is genuine
          window.Tawk_API.setAttributes(
            {
              name: visitorName,
              email: visitorEmail,
              hash: visitorHash || ''
            },
            function (error) {
              if (error) {
                console.error('Error setting Tawk API attributes:', error)
              }
            }
          )
        }
      }
    }

    // Cleanup the script element on component unmount
    return () => {
      document.body.removeChild(tawkScript)
    }
  }, [visitorName, visitorEmail, visitorHash])

  return null // The widget is injected into the body, so nothing is rendered here
}

export default TawkToWidget
