import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { translate } from 'i18n/i18n'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get profile info
export const get_profile_info = createAsyncThunk(
  'profileInfo/get_profile_info',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/get/profile-information`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.message.length > 0
              ? response?.data?.[0]?.message
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

export const get_diploma_list = createAsyncThunk(
  'profileInfo/get_diploma_list',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(`v1/profile/get/diploma-list`)

      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
    } catch (error) {
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update profile info
export const update_profile_info = createAsyncThunk(
  'profileInfo/update_profile_info',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/update/profile-information`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.message.length > 0
              ? response?.data?.[0]?.message
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update synapp admin profile info
export const update_synapp_admin_profile_info = createAsyncThunk(
  'profileInfo/update_synapp_admin_profile_info',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/update-profile`, payload)
      if (response.status === 0) {
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        showNotification({
          type: 'error',
          message:
            response?.data?.[0]?.message.length > 0
              ? response?.data?.[0]?.message
              : translate('CommonUtils.SomethingWentWrong!')
        })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// profile validation
export const profile_validation = createAsyncThunk(
  'profileInfo/profile_validation',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile-validation`, payload)
      if (response.status === 0) {
        handleCloseDialog()
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// get profile validation request
export const get_profile_validation_request = createAsyncThunk(
  'profileInfo/get_profile_validation_request',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile-validation/get`, payload)
      if (response.status === 0) {
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// accept reject profile validation request
export const accept_reject_profile_validation_request = createAsyncThunk(
  'profileInfo/accept_reject_profile_validation_request',
  async ({ payload, showNotification, handleCloseDialog }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile-validation/end`, payload)
      if (response.status === 0) {
        handleCloseDialog()
        showNotification({
          type: 'success',
          message: response?.message
        })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)

// update synapp admin profile info
export const global_search = createAsyncThunk(
  'profileInfo/gloabl_search',
  async ({ payload }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/profile/global-search`, payload)
      if (response.status === 0) {
        // showNotification({
        //   type: 'success',
        //   message: response?.message
        // })
        return { requestPayload: payload, response: response.data }
      }
      if (response.status === 1) {
        // showNotification({
        //   type: 'error',
        //   message:
        //     response?.data?.[0]?.message.length > 0
        //       ? response?.data?.[0]?.message
        //       : translate('CommonUtils.SomethingWentWrong!')
        // })
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
