import { createSlice } from '@reduxjs/toolkit'
import {
  get_organs_list,
  get_profession_list,
  add_profession,
  update_profession,
  get_expertise_list,
  add_expertise,
  update_expertise,
  get_diploma_list,
  add_diploma,
  update_diploma,
  get_language_list,
  add_organ,
  get_profession_select_list,
  add_speciality,
  update_speciality,
  update_organ,
  get_specialities_list,
  get_specialities_list_for_subspecialities,
  get_sub_specialities_list,
  add_sub_specialities,
  update_sub_specialities,
  get_diagnosis_list,
  get_organs_select_list,
  get_diagnosis_select_list,
  add_diagnosis,
  update_diagnosis,
  add_language,
  update_language,
  get_universities_list,
  add_university,
  update_university,
  get_tags_list,
  add_tag,
  update_tag
} from '../services/medicalSettingsService'

export const medicalSettingsSlice = createSlice({
  name: 'medicalSettings',
  initialState: {
    getProfessionList: {
      loading: false,
      getProfeesion: null,
      pagination: null
    },
    getProfessionSelectList: {
      loading: false,
      data: null
    },
    addSpeciality: {
      loading: false
    },
    updateSpeciality: {
      loading: false
    },
    getSpecialitiesList: {
      data: null,
      loading: false
    },
    getSpecialitiesListForSubSpecialities: {
      data: null,
      loading: false
    },
    getSubSpecialitiesList: {
      data: null,
      loading: false
    },
    addSubSpecialities: {
      loading: false
    },
    updateSubSpecialities: {
      loading: false
    },
    getOrgansList: {
      getOrgans: null,
      loading: false
    },
    getOrgansSelectList: {
      loading: false,
      data: null
    },
    addOrgan: {
      loading: false
    },
    updateOrgan: {
      loading: false
    },
    getDiagnosisList: {
      data: null,
      loading: false
    },
    getDiagnosisSelectList: {
      loading: false,
      data: null
    },
    addDiagnosis: {
      loading: false
    },
    updateDiagnosis: {
      loading: false
    },
    addUpdateProfession: {
      loading: false
    },
    getExpertiseList: {
      loading: false,
      getExpertise: null,
      pagination: null
    },
    addEditExpertise: {
      loading: false
    },
    getUniversitiesList: {
      loading: false,
      data: null
    },
    addUniversity: {
      loading: false
    },
    updateUniversity: {
      loading: false
    },
    getDiplomaList: {
      loading: false,
      getDiploma: null,
      pagination: null
    },
    addEditDiploma: {
      loading: false
    },
    getTagsList: {
      loading: false,
      data: null
    },
    addEditTag: {
      loading: false
    },
    getLanguageList: {
      loading: false,
      getLanguage: null,
      pagination: null
    },
    addEditLanguage: {
      loading: false
    }
  },
  extraReducers: (builder) => {
    // Get Profession List
    builder.addCase(get_profession_list.pending, (state) => {
      state.getProfessionList.loading = true
    })
    builder.addCase(get_profession_list.fulfilled, (state, action) => {
      state.getProfessionList.getProfeesion = action.payload?.response?.data
      state.getProfessionList.pagination = action.payload?.response?.pagination
      state.getProfessionList.loading = false
    })
    builder.addCase(get_profession_list.rejected, (state) => {
      state.getProfessionList.loading = false
    })

    // Get Profession Select List
    builder.addCase(get_profession_select_list.pending, (state) => {
      state.getProfessionSelectList.loading = true
    })
    builder.addCase(get_profession_select_list.fulfilled, (state, action) => {
      state.getProfessionSelectList.data = {
        data: action.payload?.response?.data?.map((element) => ({
          label: element?.display_name,
          value: element?.value
        })),
        pagination: action.payload?.response?.pagination
      }
      state.getProfessionSelectList.loading = false
    })

    builder.addCase(get_profession_select_list.rejected, (state) => {
      state.getProfessionSelectList.loading = false
    })

    // Add Profession
    builder.addCase(add_profession.pending, (state) => {
      state.addUpdateProfession.loading = true
    })
    builder.addCase(add_profession.fulfilled, (state) => {
      state.addUpdateProfession.loading = false
    })
    builder.addCase(add_profession.rejected, (state) => {
      state.addUpdateProfession.loading = false
    })

    // Update Profession
    builder.addCase(update_profession.pending, (state) => {
      state.addUpdateProfession.loading = true
    })
    builder.addCase(update_profession.fulfilled, (state) => {
      state.addUpdateProfession.loading = false
    })
    builder.addCase(update_profession.rejected, (state) => {
      state.addUpdateProfession.loading = false
    })

    // Get specialities List
    builder.addCase(get_specialities_list.pending, (state) => {
      state.getSpecialitiesList.loading = true
    })
    builder.addCase(get_specialities_list.fulfilled, (state, action) => {
      state.getSpecialitiesList.data = action.payload?.response
      state.getSpecialitiesList.loading = false
    })
    builder.addCase(get_specialities_list.rejected, (state) => {
      state.getSpecialitiesList.loading = false
    })

    // Add Speciality
    builder.addCase(add_speciality.pending, (state) => {
      state.addSpeciality.loading = true
    })
    builder.addCase(add_speciality.fulfilled, (state) => {
      state.addSpeciality.loading = false
    })
    builder.addCase(add_speciality.rejected, (state) => {
      state.addSpeciality.loading = false
    })

    // Update Speciality
    builder.addCase(update_speciality.pending, (state) => {
      state.updateSpeciality.loading = true
    })
    builder.addCase(update_speciality.fulfilled, (state) => {
      state.updateSpeciality.loading = false
    })
    builder.addCase(update_speciality.rejected, (state) => {
      state.updateSpeciality.loading = false
    })

    // Get specialities List for sub specialities
    builder.addCase(get_specialities_list_for_subspecialities.pending, (state) => {
      state.getSpecialitiesListForSubSpecialities.loading = true
    })
    builder.addCase(get_specialities_list_for_subspecialities.fulfilled, (state, action) => {
      state.getSpecialitiesListForSubSpecialities.data = {
        data: action.payload?.response?.data?.map((element) => ({
          speciality: element?.specialty_details[0]?.display_name,
          value: element?.specialty_details[0]?.value,
          id: element?.specialty_details[0]?.value,
          speciality_count: element?.specialty_count
        })),
        pagination: action?.payload?.response?.pagination
      }
      state.getSpecialitiesListForSubSpecialities.loading = false
    })
    builder.addCase(get_specialities_list_for_subspecialities.rejected, (state) => {
      state.getSpecialitiesListForSubSpecialities.loading = false
    })

    // Get sub specialities List
    builder.addCase(get_sub_specialities_list.pending, (state) => {
      state.getSubSpecialitiesList.loading = true
    })
    builder.addCase(get_sub_specialities_list.fulfilled, (state, action) => {
      const sub_specialities = action?.payload?.response
      const speciality_value = action?.payload?.payload?.specialty
      const modified_data = state.getSpecialitiesListForSubSpecialities.data?.data?.find(
        (element) => element.value === speciality_value
      )
      if (modified_data) modified_data.subSpecialityList = sub_specialities
      state.getSpecialitiesListForSubSpecialities.data = {
        data: modified_data,
        ...state.getSpecialitiesListForSubSpecialities.data
      }
      state.getSubSpecialitiesList.loading = false
    })
    builder.addCase(get_sub_specialities_list.rejected, (state) => {
      state.getSubSpecialitiesList.loading = false
    })

    // Add sub specialities
    builder.addCase(add_sub_specialities.pending, (state) => {
      state.addSubSpecialities.loading = true
    })
    builder.addCase(add_sub_specialities.fulfilled, (state) => {
      state.addSubSpecialities.loading = false
    })
    builder.addCase(add_sub_specialities.rejected, (state) => {
      state.addSubSpecialities.loading = false
    })

    // Update sub specialities
    builder.addCase(update_sub_specialities.pending, (state) => {
      state.updateSubSpecialities.loading = true
    })
    builder.addCase(update_sub_specialities.fulfilled, (state) => {
      state.updateSubSpecialities.loading = false
    })
    builder.addCase(update_sub_specialities.rejected, (state) => {
      state.updateSubSpecialities.loading = false
    })

    // Get Organs List
    builder.addCase(get_organs_list.pending, (state) => {
      state.getOrgansList.loading = true
    })
    builder.addCase(get_organs_list.fulfilled, (state, action) => {
      state.getOrgansList.getOrgans = action.payload?.response
      state.getOrgansList.loading = false
    })
    builder.addCase(get_organs_list.rejected, (state) => {
      state.getOrgansList.loading = false
    })

    // Get Organs Select List
    builder.addCase(get_organs_select_list.pending, (state) => {
      state.getOrgansSelectList.loading = true
    })
    builder.addCase(get_organs_select_list.fulfilled, (state, action) => {
      state.getOrgansSelectList.data = {
        data: action.payload?.response?.data?.map((element) => ({
          label: element?.display_name,
          value: element?.value
        })),
        pagination: action.payload?.response?.pagination
      }
      state.getOrgansSelectList.loading = false
    })
    builder.addCase(get_organs_select_list.rejected, (state) => {
      state.getOrgansSelectList.loading = false
    })

    // Add Organ
    builder.addCase(add_organ.pending, (state) => {
      state.addOrgan.loading = true
    })
    builder.addCase(add_organ.fulfilled, (state) => {
      state.addOrgan.loading = false
    })
    builder.addCase(add_organ.rejected, (state) => {
      state.addOrgan.loading = false
    })

    // Update Organ
    builder.addCase(update_organ.pending, (state) => {
      state.updateOrgan.loading = true
    })
    builder.addCase(update_organ.fulfilled, (state) => {
      state.updateOrgan.loading = false
    })
    builder.addCase(update_organ.rejected, (state) => {
      state.updateOrgan.loading = false
    })

    // Get Diagnosis List
    builder.addCase(get_diagnosis_list.pending, (state) => {
      state.getDiagnosisList.loading = true
    })
    builder.addCase(get_diagnosis_list.fulfilled, (state, action) => {
      state.getDiagnosisList.data = action.payload?.response
      state.getDiagnosisList.loading = false
    })
    builder.addCase(get_diagnosis_list.rejected, (state) => {
      state.getDiagnosisList.loading = false
    })

    // Get Diagnosis Select List
    builder.addCase(get_diagnosis_select_list.pending, (state) => {
      state.getDiagnosisSelectList.loading = true
    })
    builder.addCase(get_diagnosis_select_list.fulfilled, (state, action) => {
      state.getDiagnosisSelectList.data = {
        data: action.payload?.response?.data?.map((element) => ({
          label: element?.display_name,
          value: element?.value
        })),
        pagination: action.payload?.response?.pagination
      }
      state.getDiagnosisSelectList.loading = false
    })
    builder.addCase(get_diagnosis_select_list.rejected, (state) => {
      state.getDiagnosisSelectList.loading = false
    })

    // Add Diagnosis
    builder.addCase(add_diagnosis.pending, (state) => {
      state.addDiagnosis.loading = true
    })
    builder.addCase(add_diagnosis.fulfilled, (state) => {
      state.addDiagnosis.loading = false
    })
    builder.addCase(add_diagnosis.rejected, (state) => {
      state.addDiagnosis.loading = false
    })

    // Update Diagnosis
    builder.addCase(update_diagnosis.pending, (state) => {
      state.updateDiagnosis.loading = true
    })
    builder.addCase(update_diagnosis.fulfilled, (state) => {
      state.updateDiagnosis.loading = false
    })
    builder.addCase(update_diagnosis.rejected, (state) => {
      state.updateDiagnosis.loading = false
    })

    // Get Expertise List
    builder.addCase(get_expertise_list.pending, (state) => {
      state.getExpertiseList.loading = true
    })
    builder.addCase(get_expertise_list.fulfilled, (state, action) => {
      state.getExpertiseList.getExpertise = action.payload?.response?.data
      state.getExpertiseList.pagination = action.payload?.response?.pagination
      state.getExpertiseList.loading = false
    })
    builder.addCase(get_expertise_list.rejected, (state) => {
      state.getExpertiseList.loading = false
    })

    // Add Expertise
    builder.addCase(add_expertise.pending, (state) => {
      state.addEditExpertise.loading = true
    })
    builder.addCase(add_expertise.fulfilled, (state) => {
      state.addEditExpertise.loading = false
    })
    builder.addCase(add_expertise.rejected, (state) => {
      state.addEditExpertise.loading = false
    })

    // Update Expertise
    builder.addCase(update_expertise.pending, (state) => {
      state.addEditExpertise.loading = true
    })
    builder.addCase(update_expertise.fulfilled, (state) => {
      state.addEditExpertise.loading = false
    })
    builder.addCase(update_expertise.rejected, (state) => {
      state.addEditExpertise.loading = false
    })

    // Get Universities List
    builder.addCase(get_universities_list.pending, (state) => {
      state.getUniversitiesList.loading = true
    })
    builder.addCase(get_universities_list.fulfilled, (state, action) => {
      state.getUniversitiesList.data = action.payload?.response
      state.getUniversitiesList.loading = false
    })
    builder.addCase(get_universities_list.rejected, (state) => {
      state.getUniversitiesList.loading = false
    })

    // Add University
    builder.addCase(add_university.pending, (state) => {
      state.addUniversity.loading = true
    })
    builder.addCase(add_university.fulfilled, (state) => {
      state.addUniversity.loading = false
    })
    builder.addCase(add_university.rejected, (state) => {
      state.addUniversity.loading = false
    })

    // Update University
    builder.addCase(update_university.pending, (state) => {
      state.updateUniversity.loading = true
    })
    builder.addCase(update_university.fulfilled, (state) => {
      state.updateUniversity.loading = false
    })
    builder.addCase(update_university.rejected, (state) => {
      state.updateUniversity.loading = false
    })

    // Get Diploma List
    builder.addCase(get_diploma_list.pending, (state) => {
      state.getDiplomaList.loading = true
    })
    builder.addCase(get_diploma_list.fulfilled, (state, action) => {
      state.getDiplomaList.getDiploma = action.payload?.response?.data
      state.getDiplomaList.pagination = action.payload?.response?.pagination
      state.getDiplomaList.loading = false
    })
    builder.addCase(get_diploma_list.rejected, (state) => {
      state.getDiplomaList.loading = false
    })

    // Add Diploma
    builder.addCase(add_diploma.pending, (state) => {
      state.addEditDiploma.loading = true
    })
    builder.addCase(add_diploma.fulfilled, (state) => {
      state.addEditDiploma.loading = false
    })
    builder.addCase(add_diploma.rejected, (state) => {
      state.addEditDiploma.loading = false
    })

    // Update Diploma
    builder.addCase(update_diploma.pending, (state) => {
      state.addEditDiploma.loading = true
    })
    builder.addCase(update_diploma.fulfilled, (state) => {
      state.addEditDiploma.loading = false
    })
    builder.addCase(update_diploma.rejected, (state) => {
      state.addEditDiploma.loading = false
    })

    // Get Tags List
    builder.addCase(get_tags_list.pending, (state) => {
      state.getTagsList.loading = true
    })
    builder.addCase(get_tags_list.fulfilled, (state, action) => {
      state.getTagsList.data = action.payload?.response
      state.getTagsList.loading = false
    })
    builder.addCase(get_tags_list.rejected, (state) => {
      state.getTagsList.loading = false
    })

    // Add Tag
    builder.addCase(add_tag.pending, (state) => {
      state.addEditTag.loading = true
    })
    builder.addCase(add_tag.fulfilled, (state) => {
      state.addEditTag.loading = false
    })
    builder.addCase(add_tag.rejected, (state) => {
      state.addEditTag.loading = false
    })

    // Update Tag
    builder.addCase(update_tag.pending, (state) => {
      state.addEditTag.loading = true
    })
    builder.addCase(update_tag.fulfilled, (state) => {
      state.addEditTag.loading = false
    })
    builder.addCase(update_tag.rejected, (state) => {
      state.addEditTag.loading = false
    })

    // Get Language List
    builder.addCase(get_language_list.pending, (state) => {
      state.getLanguageList.loading = true
    })
    builder.addCase(get_language_list.fulfilled, (state, action) => {
      state.getLanguageList.getLanguage = action.payload?.response?.data
      state.getLanguageList.pagination = action.payload?.response?.pagination
      state.getLanguageList.loading = false
    })
    builder.addCase(get_language_list.rejected, (state) => {
      state.getLanguageList.loading = false
    })

    // Add Language
    builder.addCase(add_language.pending, (state) => {
      state.addEditLanguage.loading = true
    })
    builder.addCase(add_language.fulfilled, (state) => {
      state.addEditLanguage.loading = false
    })
    builder.addCase(add_language.rejected, (state) => {
      state.addEditLanguage.loading = false
    })

    // Update Language
    builder.addCase(update_language.pending, (state) => {
      state.addEditLanguage.loading = true
    })
    builder.addCase(update_language.fulfilled, (state) => {
      state.addEditLanguage.loading = false
    })
    builder.addCase(update_language.rejected, (state) => {
      state.addEditLanguage.loading = false
    })
  }
})

export default medicalSettingsSlice.reducer
