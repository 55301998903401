import { roles } from '../../constants'

export class AppPermissionsConfig {
  static APP_USER_ROLE_KEY = 'role'
  static APP_USER_PERMISSIONS_OBJ_KEY = 'admin_permissions'

  static getAdminPermissionsObjFromArr = (userDetails, selectedRole) => {
    const slug = this.getRoleSlugs(userDetails, selectedRole)
    const admin_roles_arr = userDetails?.activityDetail?.admin_user ?? []

    const admin_permissions_obj = admin_roles_arr
      .filter(({ sub_type }) => sub_type === slug)
      .reduce((accumulator, { name, has_permission, sub_type }) => {
        accumulator.role = sub_type
        accumulator.admin_permissions = {
          ...accumulator.admin_permissions,
          [name]: has_permission
        }
        return accumulator
      }, {})

    return admin_permissions_obj
  }

  static getRoleSlugs = (userDetails, selectedRole) => {
    const roleMapping = {
      [roles.physician]: userDetails?.is_profile_verified ? 'USER_VALIDATE' : 'USER_NOT_VALIDATE',
      [roles.patient]: 'USER_PATIENT',
      [roles.superAdmin]: 'ADMIN_SYNAPP',
      [roles.author]: 'ADMIN_AUTHOR',
      [roles.editor]: 'ADMIN_EDITOR'
    }
    return roleMapping[selectedRole] ?? null
  }
}
