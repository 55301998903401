import { createSlice } from '@reduxjs/toolkit'
import { get_video_call_token } from '../services/videoCallService'

export const videoCallSlice = createSlice({
  name: 'videoCall',
  initialState: {
    sessionId: '',
    token: ''
  },
  extraReducers: (builder) => {
    // Get my requests
    builder.addCase(get_video_call_token.pending, (state) => {
      console.log(state)
    })
    builder.addCase(get_video_call_token.fulfilled, (state, action) => {
      state.sessionId = action.payload.response.session_id
      state.token = action.payload.response.token
    })
    builder.addCase(get_video_call_token.rejected, (state) => {
      console.log(state)
    })
  }
})

export default videoCallSlice.reducer
