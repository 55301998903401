import { createSlice } from '@reduxjs/toolkit'
import { get_notifications_list } from '../services/notificationsWidgetService'

const initialState = {
  getNotificationsList: {
    loading: false,
    notificationListData: null
  }
}

export const notificationsWidgetSlice = createSlice({
  name: 'notificationsWidget',
  initialState,
  reducers: {
    readNotification: (state, action) => {
      // Find the element to update
      let notificationDetail = state.getNotificationsList?.notificationListData?.data?.findIndex(
        (notification) => notification.id === action.payload
      )
      // If the element exists in the array
      // Update the notification status
      if (
        notificationDetail >= 0 &&
        !state.getNotificationsList?.notificationListData?.data?.[notificationDetail]?.is_read
      ) {
        state.getNotificationsList.notificationListData.data[notificationDetail] = {
          ...state.getNotificationsList?.notificationListData?.data?.[notificationDetail],
          is_read: true
        }
        state.getNotificationsList.notificationListData.unreadCount =
          state.getNotificationsList.notificationListData.unreadCount - 1
        state.getNotificationsList.notificationListData.totalCount =
          state.getNotificationsList.notificationListData.totalCount - 1
      }
    },
    clearNotificationsData: (state) => {
      state.getNotificationsList = initialState.getNotificationsList
    }
  },
  extraReducers: (builder) => {
    // Get Notifications List
    builder.addCase(get_notifications_list.pending, (state) => {
      state.getNotificationsList.loading = true
    })
    builder.addCase(get_notifications_list.fulfilled, (state, action) => {
      state.getNotificationsList.loading = false
      state.getNotificationsList.notificationListData = action.payload?.response
    })
    builder.addCase(get_notifications_list.rejected, (state) => {
      state.getNotificationsList.loading = false
    })
  }
})

// Action creators are generated for each case reducer function
export const { readNotification, clearNotificationsData } = notificationsWidgetSlice.actions

export default notificationsWidgetSlice.reducer
