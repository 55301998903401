import AppAssetsConfig from 'assets/config/AppAssetsConfig'
import './FallbackLoader.scss'

const SpinLoader = () => {
  return (
    <div className="loading-gl-container" data-testid="loading-gl-container">
      <img src={AppAssetsConfig.FALLBACK_LOADER_GIF} className="loading-gl-image" alt="Loading" />
    </div>
  )
}

export default SpinLoader
