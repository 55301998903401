import { Avatar } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import './VerifiedBadge.scss'
import { VerifiedTick } from 'assets/svg'

const VerifiedBadge = (props) => {
  return (
    <div className="verified-badge-container verified-badge-wrapper">
      <Avatar
        gap={5}
        size={48}
        icon={<UserOutlined />}
        className="verified-badge-wrapper"
        {...props}
      />

      <VerifiedTick className="verified-badge-tick" />
    </div>
  )
}

export default VerifiedBadge
