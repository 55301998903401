import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from 'axiosInstance'
import { displayMultipleNotifications } from 'utils/notificationsUtils'

// get hcp list
export const get_hcp_list = createAsyncThunk(
  'hcp/get_hcp_list',
  async ({ payload, showNotification }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`v1/admin/setting/member/hcp-list`, payload)
      if (response.status === 0) {
        return { response: response.data }
      }
      if (response.status === 1) {
        displayMultipleNotifications(response.data, showNotification)
      }
    } catch (error) {
      // Handle errors and rejection
      return rejectWithValue(error.response ? error.response.data : error.message)
    }
  }
)
