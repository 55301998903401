import AppAssetsConfig from 'assets/config/AppAssetsConfig'
import './FallbackLoader.scss'

const FallbackLoader = () => {
  return (
    <div className="loading-container" data-testid="loading-container">
      <img src={AppAssetsConfig.FALLBACK_LOADER_GIF} className="loading-image" alt="Loading" />
    </div>
  )
}

export default FallbackLoader
