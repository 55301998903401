import { createSlice } from '@reduxjs/toolkit'
import {
  get_manage_discount_list,
  create_coupon_code,
  update_coupon_code,
  get_user_level_activity,
  delete_coupon_code,
  get_subscription_plan,
  get_activity,
  create_subscription_plan,
  update_subscription_plan,
  verify_coupon_code,
  subscribe_plan,
  get_discount_usage_list
} from '../services/rolesAndPermissionService'

export const rolesAndPermissionSlice = createSlice({
  name: 'rolesAndPermission',
  initialState: {
    getManageDiscountList: {
      loading: false,
      manageDiscountData: [],
      pagination: {}
    },
    createAndUpdateCouponCode: {
      loading: false
    },
    getUserLevelActivities: {
      userLevelPermissions: {},
      adminLevelPermissions: {}
    },
    getActivities: {
      permissions: {},
      permissionsArr: [],
      loading: false
    },
    deleteCouponCode: {
      loading: false
    },
    subscriptionPlan: {
      subscriptionPlanList: [],
      loading: false
    },
    createSubscriptionPlan: {
      loading: false
    },
    updateSubscriptionPlan: {
      loading: false
    },
    verifyCouponCode: {
      data: null,
      loading: false
    },
    subscribePlan: {
      data: null,
      loading: false
    },
    getDiscountUsageList: {
      loading: false,
      manageDiscountUsageData: [],
      pagination: {},
      usedDiscountCodeCount: 0,
      discountCodeGenerated: 0
    }
  },
  reducers: {
    changePackageInfoPermissions: (state, action) => {
      let tempActivities = []
      state.getActivities.permissions = {
        ...state.getActivities.permissions,
        sub_type_data: state.getActivities?.permissions?.sub_type_data?.map((element) => {
          return {
            ...element,
            activity: element?.activity?.map((_element) => {
              const activitiesArr =
                _element.id === action?.payload?.id
                  ? { ..._element, has_permission: !_element?.has_permission }
                  : _element
              tempActivities.push(activitiesArr)
              return activitiesArr
            })
          }
        })
      }
      state.getActivities.permissionsArr = tempActivities
    },
    clearCouponCodeDetails: (state) => {
      state.verifyCouponCode.data = null
      state.verifyCouponCode.loading = false
    }
  },
  extraReducers: (builder) => {
    // Get Manage Discount
    builder.addCase(get_manage_discount_list.pending, (state) => {
      state.getManageDiscountList.loading = true
    })
    builder.addCase(get_manage_discount_list.fulfilled, (state, action) => {
      state.getManageDiscountList.loading = false
      const updatedManageDiscountData = action.payload?.response?.data?.map((item) => {
        const { id, name, discount_code, discount_percentage, expire_date, service } = item
        return {
          id,
          discountName: name,
          discountCode: discount_code,
          target: service,
          discountPercent: discount_percentage,
          expiryDate: expire_date
        }
      })
      state.getManageDiscountList.manageDiscountData = updatedManageDiscountData
      state.getManageDiscountList.pagination = action.payload?.response?.pagination
    })
    builder.addCase(get_manage_discount_list.rejected, (state) => {
      state.getManageDiscountList.loading = false
    })

    // Create Coupon Code
    builder.addCase(create_coupon_code.pending, (state) => {
      state.createAndUpdateCouponCode.loading = true
    })
    builder.addCase(create_coupon_code.fulfilled, (state) => {
      state.createAndUpdateCouponCode.loading = false
    })
    builder.addCase(create_coupon_code.rejected, (state) => {
      state.createAndUpdateCouponCode.loading = false
    })

    // Update Coupon Code
    builder.addCase(update_coupon_code.pending, (state) => {
      state.createAndUpdateCouponCode.loading = true
    })
    builder.addCase(update_coupon_code.fulfilled, (state) => {
      state.createAndUpdateCouponCode.loading = false
    })
    builder.addCase(update_coupon_code.rejected, (state) => {
      state.createAndUpdateCouponCode.loading = false
    })

    // get user level activities
    builder.addCase(get_user_level_activity.pending, (state) => {
      state.getUserLevelActivities.loading = true
    })
    builder.addCase(get_user_level_activity.fulfilled, (state, action) => {
      state.getUserLevelActivities.userLevelPermissions = action.payload?.response?.filter(
        (element) => element.type === 'USER'
      )[0]
      state.getUserLevelActivities.adminLevelPermissions = action.payload?.response?.filter(
        (element) => element.type === 'ADMIN'
      )[0]
      state.getUserLevelActivities.loading = false
    })
    builder.addCase(get_user_level_activity.rejected, (state) => {
      state.getUserLevelActivities.loading = false
    })

    // get activities
    builder.addCase(get_activity.pending, (state) => {
      state.getActivities.loading = true
    })
    builder.addCase(get_activity.fulfilled, (state, action) => {
      let permissions = {
        type: '',
        sub_type_data: []
      }
      action.payload?.response?.forEach((element) => {
        permissions['type'] = element?.type
      })
      permissions['sub_type_data'].push({
        sub_type: 'SUBSCRIPTION_TYPE',
        activity: action?.payload?.response
      })

      state.getActivities.permissions = permissions
      state.getActivities.loading = false
    })
    builder.addCase(get_activity.rejected, (state) => {
      state.getActivities.loading = false
    })

    // Delete Coupon Code
    builder.addCase(delete_coupon_code.pending, (state) => {
      state.deleteCouponCode.loading = true
    })
    builder.addCase(delete_coupon_code.fulfilled, (state) => {
      state.deleteCouponCode.loading = false
    })
    builder.addCase(delete_coupon_code.rejected, (state) => {
      state.deleteCouponCode.loading = false
    })

    // Get Subscription plan
    builder.addCase(get_subscription_plan.pending, (state) => {
      state.subscriptionPlan.loading = true
    })
    builder.addCase(get_subscription_plan.fulfilled, (state, action) => {
      state.subscriptionPlan.loading = false
      state.subscriptionPlan.subscriptionPlanList = action.payload?.response
    })
    builder.addCase(get_subscription_plan.rejected, (state) => {
      state.subscriptionPlan.loading = false
    })

    // Create Subscription Plan
    builder.addCase(create_subscription_plan.pending, (state) => {
      state.createSubscriptionPlan.loading = true
    })
    builder.addCase(create_subscription_plan.fulfilled, (state) => {
      state.createSubscriptionPlan.loading = false
    })
    builder.addCase(create_subscription_plan.rejected, (state) => {
      state.createSubscriptionPlan.loading = false
    })

    // Update Subscription Plan
    builder.addCase(update_subscription_plan.pending, (state) => {
      state.updateSubscriptionPlan.loading = true
    })
    builder.addCase(update_subscription_plan.fulfilled, (state) => {
      state.updateSubscriptionPlan.loading = false
    })
    builder.addCase(update_subscription_plan.rejected, (state) => {
      state.updateSubscriptionPlan.loading = false
    })

    // Verify Coupon code
    builder.addCase(verify_coupon_code.pending, (state) => {
      state.verifyCouponCode.loading = true
    })
    builder.addCase(verify_coupon_code.fulfilled, (state, action) => {
      state.verifyCouponCode.loading = false
      state.verifyCouponCode.data = action.payload?.response
    })
    builder.addCase(verify_coupon_code.rejected, (state) => {
      state.verifyCouponCode.loading = false
    })

    // Subscribe plan
    builder.addCase(subscribe_plan.pending, (state) => {
      state.subscribePlan.loading = true
    })
    builder.addCase(subscribe_plan.fulfilled, (state, action) => {
      state.subscribePlan.loading = false
      state.subscribePlan.data = action.payload?.response
    })
    builder.addCase(subscribe_plan.rejected, (state) => {
      state.subscribePlan.loading = false
    })

    // Get Discount Usage List
    builder.addCase(get_discount_usage_list.pending, (state) => {
      state.getDiscountUsageList.loading = true
    })
    builder.addCase(get_discount_usage_list.fulfilled, (state, action) => {
      state.getDiscountUsageList.loading = false
      state.getDiscountUsageList.manageDiscountUsageData = action.payload?.response?.data
      state.getDiscountUsageList.pagination = action.payload?.response?.pagination || {}
      state.getDiscountUsageList.usedDiscountCodeCount = action.payload?.response?.data?.length || 0
      state.getDiscountUsageList.discountCodeGenerated =
        action.payload?.response?.total_generated_codes || 0
    })
    builder.addCase(get_discount_usage_list.rejected, (state) => {
      state.getDiscountUsageList.loading = false
    })
  }
})

export const { changePackageInfoPermissions, clearCouponCodeDetails } =
  rolesAndPermissionSlice.actions

export default rolesAndPermissionSlice.reducer
