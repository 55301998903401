import { createSlice } from '@reduxjs/toolkit'
import {
  get_questionnaire_list,
  get_questionnaire,
  update_questionnaire
} from '../services/questionLibraryService'

export const questionLibrarySlice = createSlice({
  name: 'questionLibrary',
  initialState: {
    getQuestionnaireList: {
      loading: false,
      getQuestionnaire: [],
      pagination: {}
    },
    getQuestionnaire: {
      loading: false,
      questionnaireData: []
    },
    updateQuestionnaire: {
      loading: false
    }
  },
  reducers: {
    clearQuestionLibrary: (state) => {
      state.getQuestionnaire.questionnaireData = []
    }
  },
  extraReducers: (builder) => {
    // Get Questionnaire List
    builder.addCase(get_questionnaire_list.pending, (state) => {
      state.getQuestionnaireList.loading = true
    })
    builder.addCase(get_questionnaire_list.fulfilled, (state, action) => {
      state.getQuestionnaireList.getQuestionnaire = action.payload?.response?.data
      state.getQuestionnaireList.pagination = action.payload?.response?.pagination
      state.getQuestionnaireList.loading = false
    })
    builder.addCase(get_questionnaire_list.rejected, (state) => {
      state.getQuestionnaireList.loading = false
    })

    // Get Questionnaire
    builder.addCase(get_questionnaire.pending, (state) => {
      state.getQuestionnaire.loading = true
    })
    builder.addCase(get_questionnaire.fulfilled, (state, action) => {
      state.getQuestionnaire.questionnaireData = action.payload?.response
      state.getQuestionnaire.loading = false
    })
    builder.addCase(get_questionnaire.rejected, (state) => {
      state.getQuestionnaire.loading = false
    })

    // Update Questionnaire
    builder.addCase(update_questionnaire.pending, (state) => {
      state.updateQuestionnaire.loading = true
    })
    builder.addCase(update_questionnaire.fulfilled, (state) => {
      state.updateQuestionnaire.loading = false
    })
    builder.addCase(update_questionnaire.rejected, (state) => {
      state.updateQuestionnaire.loading = false
    })
  }
})

export const { clearQuestionLibrary } = questionLibrarySlice.actions
export default questionLibrarySlice.reducer
