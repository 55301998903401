import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Dropdown,
  Flex,
  Row,
  Select,
  Spin,
  Tag,
  Tooltip,
  Typography
} from 'antd'
import BreadCrumbs from '../bread-crumbs'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  SearchOutlined,
  UserOutlined,
  ArrowLeftOutlined,
  ReadOutlined,
  ClockCircleOutlined
} from '@ant-design/icons'
import { ArrowDownIcon, GlobalIcon, NotificationIcon, BroadCastIcon } from 'assets/svg'
import VerifiedBadge from '../verified-badge/VerifiedBadge'
import { useTranslation } from 'react-i18next'
// import { optionsData } from './Data'
import SelectableDropdownButton from '../selectable-dropdown-button'
import NotificationWidget from '../notification-widget/Index'
import useClickOutside from 'hooks/useClickOutside'
import { useTourComponentRefs } from 'context/TourRefsProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppRoutingConfig } from 'assets/config/AppRoutingConfig'
import SynappTourDialog from './SynappTourDialog'
import { roles } from '../../../constants'
import { useUserDetails } from 'context/UserDetailsProvider'
import {
  AuthService,
  CommonService,
  NotificationsWidgetService,
  ProfileInfoService
} from '../../../redux/services'
import { useDispatch, useSelector } from 'react-redux'
import { useNotify } from 'context/NotificationProvider'
import { encryptData } from 'assets/config/AppEncryptDecryptConfig'
import { setActiveProfile } from '../../../redux/reducer/activeProfileReducer'
import { clearCommonDataDetails } from '../../../redux/reducer/commonReducer'
import BroadcastWidget from '../broadcast-widget'
import { debounce } from 'lodash'
import { clearGlobalSearchList } from '../../../redux/reducer/profileInfoReducer'
import './Navbar.scss'

const Navbar = ({ isPrimaryLayout, sidebarCollapsed, setSidebarCollapsed }) => {
  /* i18n dependencies */
  const { t } = useTranslation()
  /* i18n dependencies */

  /* location dependencies */
  const location = useLocation()
  /* location dependencies */

  /* user details dependencies */
  const { selectedRole, userDetails, selectedProfileDetails } = useUserDetails()
  /* user details dependencies */

  /* define ref */
  const broadCastRef = useRef(null)
  const notificationRef = useRef(null)
  /* define ref */

  /* dispatch dependencies */
  const dispatch = useDispatch()
  const { getCommunity } = useSelector((state) => state.common)
  const globalSearchData = useSelector((state) => state.profileInfo.globalSearchList)
  const { getNotificationsList } = useSelector((state) => state.notificationsWidget)
  /* dispatch dependencies */

  /* notification dependencies */
  const { showNotification } = useNotify()
  /* notification dependencies */

  /* navigation dependencies */
  const navigate = useNavigate()
  /* navigation dependencies */

  const ref = useTourComponentRefs()

  /* define state */
  const [searchValue, setSearchValue] = useState()
  const [languageValue, setLanguageValue] = useState('')
  const [showContent, setShowContent] = useState(true)
  const { activeProfile } = useSelector((state) => state.activeProfile)

  /* define state */

  /* collapsed dependencies */
  const [collapsed, setCollapsed] = useState(false)
  const [showBroadCast, setShowBroadCast] = useState(false)
  /* collapsed dependencies */

  /* dialog dependencies */
  const [synappTourDialogOpen, setSynappTourDialogOpen] = useState(false)

  const handleOpenSynappTourDialog = () => {
    setSynappTourDialogOpen(true)
  }

  const handleCloseSynappTourDialog = () => {
    setSynappTourDialogOpen(false)
  }

  const synappTourDialogProps = useMemo(
    () => ({
      open: synappTourDialogOpen,
      onClose: handleCloseSynappTourDialog
    }),
    [synappTourDialogOpen]
  )
  /* dialog dependencies */

  useEffect(() => {
    function handleResizeFunction(e) {
      if (e.currentTarget.innerWidth <= 768) {
        setShowContent(false)
      } else {
        setShowContent(true)
      }
    }

    // Set initial state based on the current window width
    if (window.innerWidth <= 768) {
      setShowContent(false)
    } else {
      setShowContent(true)
    }

    window.addEventListener('resize', handleResizeFunction)
    return () => {
      window.removeEventListener('resize', handleResizeFunction)
    }
  }, [])

  /* open tour dialog if user is for the first time */
  useEffect(() => {
    if ([roles.physician, roles.hcp].includes(selectedRole)) {
      if (!userDetails?.is_tour_completed) handleOpenSynappTourDialog()
    }
  }, [selectedRole, userDetails])
  /* open tour dialog if user is for the first time */

  /* check for outside click to close the widget */
  useClickOutside(notificationRef, () => setCollapsed(false))
  useClickOutside(broadCastRef, () => setShowBroadCast(false))
  /* check for outside click to close the widget */

  // const navigateBackButtonBasedOnRole = {
  //   [roles.superAdmin]: AppRoutingConfig.DASHBOARD,
  //   [roles.physician]: AppRoutingConfig.HOME,
  //   [roles.hcp]: AppRoutingConfig.HOME,
  //   [roles.editor]: AppRoutingConfig.HOME,
  //   [roles.author]: AppRoutingConfig.HOME,
  //   [roles.patient]: AppRoutingConfig.HOME
  // }

  // const navigationRoute = navigateBackButtonBasedOnRole[selectedRole]

  /* user dependencies */
  const userDropdownItems = [
    {
      key: '1',
      label: t('SignIn.GoToProfile')
    },
    {
      key: '2',
      label: t('SignIn.Logout')
    }
  ]
  /* user dependencies */

  const handleGetData = () => {
    dispatch(CommonService.get_community({ payload: {}, showNotification, userDetails }))
  }

  useEffect(() => {
    if (!getCommunity?.data) handleGetData()
    dispatch(setActiveProfile(selectedProfileDetails))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /*useEffect hook to fetch initial data when the component mounts */
  useEffect(() => {
    const getNotificationsListPayload = {
      filterType: '',
      page: 1,
      pageSize: 10
    }
    dispatch(
      NotificationsWidgetService.get_notifications_list({
        payload: getNotificationsListPayload,
        showNotification
      })
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /*useEffect hook to fetch initial data when the component mounts */

  const handleGetGlobalData = (search) => {
    const dataToBeSent = {
      search: search
    }

    if (search) {
      dispatch(ProfileInfoService.global_search({ payload: dataToBeSent }))
    }
  }
  //eslint-disable-next-line
  const debounceFn = useMemo(() => debounce(handleGetGlobalData, 500), [])

  /* Handle Search Change */
  const handleChange = (newValue) => {
    if (newValue === '') {
      setSearchValue(null)
      debounceFn(newValue)
    } else {
      setSearchValue(newValue)
      debounceFn(newValue)
    }
  }
  /* Handle Search Change */

  // Optionally handle onBlur to persist the search value
  const handleBlur = () => {
    // Keep the search value as is when clicking outside
    if (searchValue === '') {
      setSearchValue(null)
      dispatch(clearGlobalSearchList())
    }
    dispatch(clearGlobalSearchList())
  }

  // TODO : Search value to display when navigated to other pages
  const handleGlobalNavigate = (type) => {
    switch (type) {
      case 'physicians':
        navigate(AppRoutingConfig.DIRECTORY)
        // searchValue
        return

      case 'te-patients':
        navigate(AppRoutingConfig.MY_PATIENTS)
        // searchValue
        return
      case 'so-patients':
        navigate(AppRoutingConfig.MY_PATIENTS)
        // searchValue
        return
      case 'so-requests':
        navigate(AppRoutingConfig.MY_REQUESTS)
        // searchValue
        return
      case 'te-requests':
        navigate(AppRoutingConfig.MY_REQUESTS)
        // searchValue
        return
      default:
        return
    }
  }

  const globalSearchOptions = (list) => {
    return list
      ?.map((item, index) => {
        const createPersonOption = (data, handleClick) =>
          data?.map((i) => ({
            label: (
              <div
                key={i.id}
                style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
                onClick={() => handleClick(i?.user_no ? i?.user_no : i?.id)}
              >
                <ClockCircleOutlined />
                {i?.first_name} {i?.last_name}
              </div>
            ),
            value: `${i?.first_name} ${i?.last_name} - ${i?.id}` // Store first and last name in value for searching
          }))

        const createRequestOption = (data, handleClick) =>
          data?.map((i) => ({
            label: (
              <div
                key={i.id}
                style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}
                onClick={() => handleClick(i.id)}
              >
                <ClockCircleOutlined />
                {i?.request_id}
              </div>
            ),
            value: `${i?.request_id} - ${i?.id}` // Store first and last name in value for searching
          }))

        const seeMoreButton = (category) => ({
          label: (
            <Button
              type="link"
              style={{ paddingLeft: '0.5rem', color: 'var(--cyan-blue)', cursor: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation()
                handleGlobalNavigate(category)
                setOpen(false)
                setSearchValue(null)
                selectRef.current.blur()
              }}
            >
              {t('CommonUtils.seeMore')}
            </Button>
          ),
          value: `see-more-${category}-${index}`,
          disabled: true
        })

        if (item.source_table === 'auth_user') {
          const handleClick = (id) => navigate(AppRoutingConfig.PREVIEW_PROFILE + id)
          return {
            label: (
              <Typography.Title key={index} level={4} style={{ color: 'var(--cyan-blue)' }}>
                {t('CommonUtils.physicians')}
              </Typography.Title>
            ),
            options: [...createPersonOption(item?.data, handleClick), seeMoreButton('physicians')],
            key: `physicians-${index}`
          }
        }
        // if (item.source_table === 'te_patients') {
        //   const handleClick = () => navigate(AppRoutingConfig.MY_PATIENTS)
        //   return {
        //     label: (
        //       <Typography.Title key={index} level={4} style={{ color: 'var(--cyan-blue)' }}>
        //         Tele-Expertise Patients
        //       </Typography.Title>
        //     ),
        //     options: [...createPersonOption(item?.data, handleClick), seeMoreButton('te-patients')],
        //     key: `te_patients-${index}`
        //   }
        // }
        // if (item.source_table === 'so_patients') {
        //   const handleClick = () => navigate(AppRoutingConfig.MY_PATIENTS)
        //   return {
        //     label: (
        //       <Typography.Title key={index} level={4} style={{ color: 'var(--cyan-blue)' }}>
        //         Second Opinion Patients
        //       </Typography.Title>
        //     ),
        //     options: [...createPersonOption(item?.data, handleClick), seeMoreButton('so-patients')],
        //     key: `so_patients-${index}`
        //   }
        // }
        if (item.source_table === 'te_requests') {
          const handleClick = (id) => navigate(AppRoutingConfig.VIEW_CASE_REQUEST + id)
          return {
            label: (
              <Typography.Title key={index} level={4} style={{ color: 'var(--cyan-blue)' }}>
                {t('CommonUtils.teRequest')}
              </Typography.Title>
            ),
            options: [
              ...createRequestOption(item?.data, handleClick),
              seeMoreButton('te-requests')
            ],
            key: `te_requests-${index}`
          }
        }
        if (item.source_table === 'so_requests') {
          const handleClick = (id) => navigate(AppRoutingConfig.VIEW_SO_CASE_REQUEST + id)
          return {
            label: (
              <Typography.Title key={index} level={4} style={{ color: 'var(--cyan-blue)' }}>
                {t('CommonUtils.soRequest')}
              </Typography.Title>
            ),
            options: [
              ...createRequestOption(item?.data, handleClick),
              seeMoreButton('so-requests')
            ],
            key: `so_requests-${index}`
          }
        }

        return null // Return null for unmatched cases
      })
      .filter(Boolean) // Filter out any null values
  }
  const [open, setOpen] = useState(false) // To control dropdown open state
  const selectRef = useRef(null) // Ref to the Select component

  console.log(userDetails, 'userdetails')

  const availability_status = {
    available: {
      badge: <Badge status="success" />,
      title: t('CommonUtils.Available')
    },
    unavailable: {
      badge: <Badge status="error" />,
      title: t('CommonUtils.NotAvailable')
    }
  }
  return (
    <div
      className={`navbar-container navbar-wrapper ${isPrimaryLayout ? 'primary-navbar' : 'main-navbar'}`}
    >
      <Row gutter={[10, 10]} style={{ alignItems: 'center' }}>
        <Col
          xs={23}
          sm={2}
          md={!isPrimaryLayout ? (sidebarCollapsed ? 6 : 5) : 6}
          lg={!isPrimaryLayout ? (sidebarCollapsed ? 6 : 5) : 6}
          xl={5}
          xxl={6}
          className="breadcrumb-column-container"
        >
          <Flex align="center" gap="1rem">
            <BreadCrumbs isPrimaryLayout={isPrimaryLayout} />
          </Flex>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={!isPrimaryLayout ? (sidebarCollapsed ? 24 : 24) : 24}
          lg={!isPrimaryLayout ? (sidebarCollapsed ? 24 : 24) : 24}
          xl={19}
          xxl={18}
        >
          <Row gutter={[20, 20]} style={{ alignItems: 'center' }}>
            {isPrimaryLayout && (
              <Col xs={4} sm={2} md={1} lg={1} xl={1} xxl={1}>
                <Button
                  shape="circle"
                  style={{ width: 30, aspectRatio: 1 }}
                  icon={<ArrowLeftOutlined />}
                  size="large"
                  onClick={() =>
                    location.pathname.includes('settings')
                      ? selectedRole === roles.patient
                        ? navigate(AppRoutingConfig.HOME)
                        : navigate(AppRoutingConfig.DASHBOARD)
                      : navigate(-1)
                  }
                />
              </Col>
            )}
            <Col
              xs={{ span: 24, order: 2 }}
              sm={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 24 : 24) : 9, order: 1 }}
              md={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 7 : 7) : 7, order: 1 }}
              lg={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 7 : 7) : 8, order: 1 }}
              xl={{ span: 7, order: 1 }}
              xxl={{ span: 7, order: 1 }}
            >
              <Select
                showSearch
                value={searchValue}
                placeholder={
                  <div style={{ color: isPrimaryLayout && 'var(--pure-white)' }}>
                    <SearchOutlined style={{ color: isPrimaryLayout && 'var(--pure-white)' }} />
                    &nbsp; {t('CommonUtils.Search')}
                  </div>
                }
                defaultActiveFirstOption={false}
                suffixIcon={null}
                onSearch={handleChange}
                onChange={handleChange}
                onBlur={handleBlur}
                options={globalSearchOptions(globalSearchData?.data)}
                ref={selectRef} // Assign reference to the Select component
                open={open}
                onClick={() => setOpen(true)}
                filterOption={(input, option) => {
                  const searchText = input.toLowerCase()

                  // Check if the value or any nested label text includes the search input
                  const matchInValue = option?.value?.toLowerCase().includes(searchText)

                  // Check if label contains search input (matching both first_name and last_name)
                  const matchInFirstName = option?.label?.props?.children?.[1]
                    ?.toLowerCase()
                    .includes(searchText)
                  const matchInLastName = option?.label?.props?.children?.[3]
                    ?.toLowerCase()
                    .includes(searchText)

                  // Always show the "See More" option
                  const isSeeMore = option?.value?.startsWith('see-more')

                  return matchInValue || matchInFirstName || matchInLastName || isSeeMore
                }}
                style={{
                  width: '100%',
                  backgroundColor: isPrimaryLayout
                    ? 'var(--primary-dark-color)'
                    : 'var(--silver-sand)',
                  color: isPrimaryLayout && 'var(--pure-white) !important',
                  borderRadius: 12
                }}
                className={isPrimaryLayout && 'profile-selection-dropdown-white'}
                variant="borderless"
                notFoundContent={
                  globalSearchData?.loading ? (
                    <Flex align="center" justify="center">
                      <Spin />
                    </Flex>
                  ) : null
                }
              />
            </Col>

            <Col
              xs={{ span: 17, order: 2 }}
              sm={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 10 : 9) : 9, order: 1 }}
              md={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 6 : 6) : 6, order: 1 }}
              lg={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 6 : 5) : 6, order: 1 }}
              xl={{ span: 5, order: 1 }}
              xxl={{ span: 6, order: 1 }}
            >
              {![roles.superAdmin, roles.patient].includes(selectedRole) && (
                <Select
                  defaultActiveFirstOption
                  value={activeProfile}
                  options={getCommunity?.data}
                  style={{
                    width: '100%',
                    backgroundColor: isPrimaryLayout
                      ? 'var(--primary-dark-color)'
                      : 'var(--silver-sand)',
                    color: isPrimaryLayout && 'var(--pure-white) !important',
                    borderRadius: 12
                  }}
                  variant="borderless"
                  optionRender={(option) => {
                    const { data } = option
                    return (
                      <Flex gap="0.5rem">
                        <Flex style={{ minWidth: 30 }} align="center" justify="center">
                          <Avatar
                            icon={<UserOutlined />}
                            size={30}
                            src={data?.community_photo_url || data?.profile_pic}
                          />
                        </Flex>
                        <Flex gap="0.5rem" vertical justify="center">
                          <Typography.Title level={4} style={{ margin: 0 }}>
                            {data?.label}
                          </Typography.Title>
                          {data?.label !== 'Individual' && (
                            <Typography.Paragraph
                              style={{ textTransform: 'capitalize', margin: 0 }}
                            >
                              {data?.community_members?.[0]?.role}
                            </Typography.Paragraph>
                          )}
                        </Flex>
                      </Flex>
                    )
                  }}
                  onChange={(newValue) => {
                    const selectedProfiledVal = getCommunity?.data?.filter(
                      (element) => element.id === newValue
                    )
                    const selectedProfile = selectedProfiledVal[selectedProfiledVal?.length - 1]
                    if (selectedProfile?.label === 'Individual') {
                      dispatch(
                        CommonService.community_revoke_token({
                          payload: {},
                          showNotification,
                          navigate
                        })
                      )
                    } else {
                      dispatch(
                        CommonService.community_create_token({
                          payload: { community_id: selectedProfile?.id },
                          showNotification,
                          navigate
                        })
                      )
                    }
                    dispatch(setActiveProfile(selectedProfile))
                    encryptData('selectedProfile', selectedProfile)
                  }}
                  className={isPrimaryLayout && 'profile-selection-dropdown-white'}
                />
              )}
            </Col>

            <Col
              xs={{ span: 7, order: 3 }}
              sm={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 5 : 6) : 6, order: 2 }}
              md={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 4 : 4) : 4, order: 2 }}
              lg={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 5 : 5) : 4, order: 2 }}
              xl={{ span: 5, order: 2 }}
              xxl={{ span: 5, order: 2 }}
            >
              <Flex align="center" justify="center">
                <SelectableDropdownButton
                  menuItems={[
                    {
                      key: '1',
                      label: window.innerWidth >= 1025 ? t('CommonUtils.English') : 'EN'
                    },
                    {
                      key: '2',
                      label: window.innerWidth >= 1025 ? t('CommonUtils.French') : 'FR'
                    }
                  ]}
                  suffixIcon={<GlobalIcon color={isPrimaryLayout && 'var(--pure-white)'} />}
                  value={
                    languageValue ||
                    (window.innerWidth >= 1025 && t('CommonUtils.Validations.Select'))
                  }
                  setValue={setLanguageValue}
                  buttonProps={{
                    style: {
                      color: isPrimaryLayout && 'var(--pure-white)'
                    }
                  }}
                />
                <Divider
                  type="vertical"
                  style={{
                    height: '40px',
                    backgroundColor: isPrimaryLayout && 'var(--pure-white)'
                  }}
                />
                <Flex gap="0.2rem" align="center" justify="center">
                  {selectedRole === roles.physician && !isPrimaryLayout && (
                    <div
                      className={`tour-container ${!isPrimaryLayout && sidebarCollapsed ? 'tour-container-hidden' : ''}`}
                    >
                      <Tooltip title="App Tutorial">
                        <Button
                          icon={
                            <ReadOutlined
                              style={{
                                fontSize: 18,
                                color: isPrimaryLayout && 'var(--pure-white)'
                              }}
                            />
                          }
                          shape="circle"
                          type="text"
                          style={{ width: 40, aspectRatio: 1 }}
                          onClick={handleOpenSynappTourDialog}
                        />
                      </Tooltip>
                    </div>
                  )}
                  {selectedRole === roles.superAdmin && (
                    <>
                      <div className="broadcast-container" ref={broadCastRef}>
                        <BroadcastWidget
                          showBroadCast={showBroadCast}
                          setShowBroadCast={setShowBroadCast}
                        />
                        <GetCustomTooltipConditionally title="Broadcast" showTooltip={showContent}>
                          <Button
                            icon={<BroadCastIcon color={isPrimaryLayout && 'var(--pure-white)'} />}
                            shape="circle"
                            type="text"
                            style={{ width: 40, aspectRatio: 1 }}
                            onClick={() => {
                              setShowBroadCast((prevState) => !prevState)
                            }}
                          />
                        </GetCustomTooltipConditionally>
                      </div>
                      <Divider
                        type="vertical"
                        style={{
                          height: '40px',
                          backgroundColor: isPrimaryLayout && 'var(--pure-white)'
                        }}
                      />
                    </>
                  )}

                  <div
                    className="notification-container"
                    ref={showContent ? notificationRef : null}
                  >
                    <NotificationWidget
                      isCollapsed={collapsed}
                      setCollapsed={setCollapsed}
                      showContent={showContent}
                      setShowContent={setShowContent}
                    />
                    <GetCustomTooltipConditionally title="Notifications" showTooltip={showContent}>
                      <Button
                        icon={
                          <Badge count={getNotificationsList?.notificationListData?.totalCount}>
                            <NotificationIcon color={isPrimaryLayout && 'var(--pure-white)'} />
                          </Badge>
                        }
                        shape="circle"
                        type="text"
                        style={{ width: 40, aspectRatio: 1 }}
                        onClick={() => {
                          setSidebarCollapsed && !showContent && setSidebarCollapsed(true)
                          setCollapsed((prevState) => !prevState)
                        }}
                      />
                    </GetCustomTooltipConditionally>
                  </div>
                </Flex>
              </Flex>
            </Col>

            <Col
              xs={{ span: !isPrimaryLayout ? 24 : 20, order: 1 }}
              sm={{ span: 9, order: 3 }}
              md={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 7 : 7) : 6, order: 3 }}
              lg={{ span: !isPrimaryLayout ? (sidebarCollapsed ? 6 : 6) : 5, order: 3 }}
              xl={{ span: 6, order: 3 }}
              xxl={{ span: 5, order: 3 }}
              className="profile-container"
            >
              <Dropdown
                menu={{
                  items: userDropdownItems,
                  onClick: (event) => {
                    const clickedItem = userDropdownItems?.find((item) => item.key === event.key)
                    if (clickedItem.key === '1') {
                      selectedRole === roles.superAdmin
                        ? navigate(AppRoutingConfig.MY_PROFILE)
                        : navigate(AppRoutingConfig.PROFILE_INFORMATION)
                    }
                    if (clickedItem.key === '2') {
                      localStorage.clear()
                      navigate(AppRoutingConfig.APP_URL_LOGIN)
                      dispatch(AuthService.logout({ payload: {}, showNotification, navigate }))
                      dispatch(clearCommonDataDetails())
                    }
                  }
                }}
                trigger={['click']}
                placement="bottomCenter"
                arrow={{ pointAtCenter: true }}
              >
                <Flex gap="0.5rem" ref={ref?.ref1} align="center">
                  <div style={{ width: 50 }}>
                    {selectedRole === roles.physician && userDetails?.is_profile_verified ? (
                      <VerifiedBadge size={40} src={userDetails?.profile_pic} />
                    ) : (
                      <Avatar icon={<UserOutlined />} size={40} src={userDetails?.profile_pic} />
                    )}
                  </div>
                  <Flex vertical gap={selectedRole === roles.physician ? 0 : '0.2rem'}>
                    <Typography.Title
                      level={4}
                      style={{ color: isPrimaryLayout && 'var(--pure-white)' }}
                      ellipsis={true}
                    >
                      {userDetails &&
                        `${userDetails?.title || ''} ${userDetails?.first_name} ${userDetails?.last_name}`}
                    </Typography.Title>
                    {selectedRole === roles.physician ? (
                      <Button
                        type="text"
                        style={{
                          padding: 0,
                          height: 20,
                          color: isPrimaryLayout && 'var(--pure-white)'
                        }}
                      >
                        <Flex align="center" gap="0.5rem">
                          {userDetails?.availability_status && (
                            <>
                              {availability_status[userDetails?.availability_status]?.badge}
                              {availability_status[userDetails?.availability_status]?.title}
                            </>
                          )}
                          <ArrowDownIcon color={isPrimaryLayout && 'var(--pure-white)'} />
                        </Flex>
                      </Button>
                    ) : selectedRole === roles.superAdmin ? (
                      <Tag color="blue" style={{ borderRadius: 6, width: 'fit-content' }}>
                        {t('CommonUtils.SuperAdmin')}
                      </Tag>
                    ) : [roles.author, roles.editor].includes(selectedRole) ? (
                      <>
                        <Flex align="center" gap="0.5rem" style={{ width: '100%' }}>
                          <Typography.Paragraph
                            style={{
                              textTransform: 'capitalize',
                              margin: 0,
                              color: isPrimaryLayout && 'var(--pure-white)'
                            }}
                          >
                            {selectedRole} | {selectedProfileDetails?.community_title}
                          </Typography.Paragraph>
                        </Flex>
                      </>
                    ) : (
                      <></>
                    )}
                  </Flex>
                </Flex>
              </Dropdown>
            </Col>
          </Row>
        </Col>
      </Row>
      <SynappTourDialog {...synappTourDialogProps} />
    </div>
  )
}

const GetCustomTooltipConditionally = ({ children, showTooltip, title }) => {
  return showTooltip ? <Tooltip title={title}>{children}</Tooltip> : children
}

export default Navbar
